<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center center">
        <div style="font-size: x-large;">{{groupSettings!.group_name}}</div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <button mat-mini-fab routerLink="/club/home/{{groupSettings!.group_id}}" [color]="getColor('home')">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-mini-fab routerLink="/club/events/{{groupSettings!.group_id}}" [color]="getColor('events')">
            <mat-icon>calendar_month</mat-icon>
          </button>
          <button mat-mini-fab routerLink="/club/roster/{{groupSettings!.group_id}}" [color]="getColor('roster')">
            <mat-icon>group_add</mat-icon>
          </button>
          <button mat-mini-fab routerLink="/club/standings/{{groupSettings!.group_id}}" [color]="getColor('standings')">
            <mat-icon>emoji_events</mat-icon>
          </button>
          <button *ngIf="isAdmin()" mat-mini-fab routerLink="/club/ledger/{{groupSettings!.group_id}}" [color]="getColor('ledger')">
            <mat-icon>paid</mat-icon>
          </button>
          <button *ngIf="isAdmin()" mat-mini-fab routerLink="/club/settings/{{groupSettings!.group_id}}/0" [color]="getColor('settings')">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
  </div>
</div>