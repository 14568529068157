import { Injectable } from '@angular/core';
import { EventInfo } from '../objects/EventInfo';
import { environment } from 'src/environments/environment';

@Injectable()
export class UtilityService {

  constructor() { }

  generateArrayFromName(eventInfo : EventInfo, name: string) : number []{
    var array= new Array();
    for ( var i = 1; i<19; i++){
      var column = name+i;
      const value = eventInfo[column as keyof EventInfo];
      array.push(value);
    }
    return array;
  }

  getScoreClass(score: number, par: number): string {

    var diff = par - score;
    var scoreClass = 'score';

    if (diff == 3 && score != 0) //albatros
    {
      scoreClass = 'albatros';

    } else if (diff == 2) //eagle
    {
      scoreClass = 'eagle';

    } else if (diff == 1) //birdie
    {
      scoreClass = 'birdie';

    } else if (diff == 0) //par
    {
      scoreClass = 'par';

    } else if (diff == -1) //bogey
    {
      scoreClass = 'bogey';

    } else if (diff < -1) //other
    {
      scoreClass = 'bogeyplus';
    }
    return scoreClass;
  }

  useUSGA(){
    return environment.use_usga;
  }


}
