import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';
import { PlayerService } from 'src/app/services/player.service';


@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private playerService : PlayerService,
    private localStorage: LocalStorageService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    let player = this.playerService.getLoggedInPlayerInfo();
    if (player !== null){
      return true;
    }
    console.log(state.url)
    this.localStorage.set("forward_url", state.url);
    console.log("Not Authenticated!");
    this.router.navigateByUrl("/login");
    return false;
  }
}
