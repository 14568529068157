<button mat-menu-item
  style="width:100%"
  #leagueMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="leagueMenu"
  [attr.id]="'trigger'+group.group_id">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <img height="30" src="{{group.icon_url || group.logo_url}}"> 
    <div>{{group.group_name}}</div>
  </div>
</button>

<mat-menu #leagueMenu="matMenu" [attr.id]="'menu'+group.group_id">
  <button mat-menu-item routerLink="/club/home/{{group.group_id}}">
    <mat-icon>home</mat-icon>
  Club Home 
  </button>
  <button mat-menu-item routerLink="/club/events/{{group.group_id}}">
    <mat-icon>
      calendar_month
    </mat-icon>
  Events    
  </button>
  <button mat-menu-item routerLink="/club/roster/{{group.group_id}}">
    <mat-icon>
      group_add
    </mat-icon>
  Player Roster    
  </button>
  <button mat-menu-item routerLink="/club/standings/{{group.group_id}}">
    <mat-icon>
      emoji_events
    </mat-icon>
  Standings
  </button>
  <button *ngIf="isAdmin()" mat-menu-item routerLink="/club/ledger/{{group.group_id}}">
    <mat-icon>
      paid
    </mat-icon>
  Ledger
  </button>
  <button *ngIf="isAdmin()" mat-menu-item routerLink="/club/settings/{{group.group_id}}/0">
    <mat-icon>
      settings
    </mat-icon>
  Club Settings
  </button>
</mat-menu>
