<div id="page-container">
  <header>
    <wagl-header></wagl-header>
  </header>
  <div class="body">
    <router-outlet id="content-wrap"></router-outlet>
  </div>
  <footer id="footer">
    <wagl-footer></wagl-footer>
  </footer>
</div>