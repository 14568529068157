import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoursesService } from './services/courses.service';
import { DocumentService } from './services/document.service';
import { MessageService } from './services/email.service';
import { EventService } from './services/event.service';
import { GroupService } from './services/group.service';
import { PlayerService } from './services/player.service';
import { UploadServiceService } from './services/upload-service.service';
import { UtilityService } from './services/utility.service';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { AuthorizedGuard } from './guards/authorized.guard';
import { LocalStorageModule } from 'angular-2-local-storage';
import { MaterialsModule } from './modules/shared/materials/materials.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClubHeaderComponent } from './components/club-header/club-header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { LeagueMenuComponent } from './components/league-menu/league-menu.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MyGroupsComponent } from './components/my-groups/my-groups.component';
import { StylesComponent } from './components/styles/styles.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { RouterModule } from '@angular/router';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { StripeService } from './services/stripe.service';
import { StripePaymentComponent } from './components/stripe-payment/stripe-payment.component';
import { SessionService } from './services/session.service';
import { AdminGuard } from './guards/admin.guard';

@NgModule({
  declarations: [
    AppComponent,
    StylesComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    HomeComponent,
    LoginComponent,
    LoginDialogComponent,
    LogoutComponent,
    MyGroupsComponent,
    LeagueMenuComponent,
    UnauthorizedComponent,
    ClubHeaderComponent,
    StripePaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialsModule,
    RichTextEditorModule,
    LocalStorageModule.forRoot({
      prefix: 'wagl',
      storageType: 'localStorage'
    })
  ],
  providers: [
    DocumentService, 
    PlayerService, 
    StripeService,
    EventService, 
    GroupService, 
    UtilityService, 
    CoursesService, 
    MessageService, 
    UploadServiceService,
    SessionService,
    AuthenticatedGuard,
    AuthorizedGuard,
    AdminGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
