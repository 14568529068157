import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'wagl-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private sessionService: SessionService,
  ) { }

  ngOnInit() {
    console.log("DashboardComponent");
    this.sessionService.removeCurrentGroup();
  }
}
