export const environment = {
  production: true,
  IMAGE_URL:'https://api.golfgameskeeper.com/',
  UPLOAD_API_URL :
  'https://api.golfgameskeeper.com/API/GGKFileUpload.asmx/',
  DOC_API_URL : 
  'https://api.golfgameskeeper.com/API/GGKDocumentService.asmx/',
  BASE_API_URL  :
  'https://api.golfgameskeeper.com/API/',
  GGK_API_KEY : 'qInc2!dNryaF7LFec9pr8mMATieQravnkOI',
  GOOGLE_LOGIN_CLIENT_ID : '586433516567-3l2dqcosq7o8rsvna2kbtmb04f7sosc4.apps.googleusercontent.com',
  GOOGLE_LOGIN_SECRET: 'jCg6q5rED95M1_O6etxn3Im-',
  FACEBOOK_APP_ID:"1594690470800195",
  STRIPE_CONNECT: "ca_F7eeIp0d7fBxxwUA4HCMPNHVldfspPzl",
  STRIPE_KEY:"pk_live_r2tZmnWgzFGiyqqpmdZR9sSg",
  SYNCFUSION_UNLOCK_KEY:"@32322e302e30GtshT9iytvlUO8gOTn5srfer0SO7w9YNYveBmAMlVN0=",
  SYNCFUSION_LICENSE_KEY:"Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXdfdnVURGNdUUF+XUs=",
  SYNCFUSION_LICENSE_KEY2: "Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUX5YcXVXQGVcWUBxXA==",
  use_usga: false
};
