import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders  } from '@angular/common/http';
import { environment } from '../../environments/environment';


const API_URL = environment.UPLOAD_API_URL;
const GGK_API_KEY = environment.GGK_API_KEY;

@Injectable()
export class UploadServiceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  post<T>(url : string, formData: FormData){
    return this.httpClient.post<T>(url, formData, {params : {'api_key' : GGK_API_KEY }})
  }

  public UploadEventAdvertizerImage(file : File, group_event_id: string, assigned_to_hole: string){
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('group_event_id', group_event_id);
    formData.append('assigned_to_hole', assigned_to_hole);
    let url = API_URL + "UploadEventAdvertizerImage";

    return this.post<any>(url, formData);

//    const req = new HttpRequest('POST', url, formData, httpOptions)
//    return this.request(req);
  }

  public UploadLeagueAdvertizerImage(file : File, group_id: number, assigned_to_hole: string){
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('group_id', group_id.toString());
    formData.append('assigned_to_hole', assigned_to_hole);
    let url = API_URL + "UploadLeagueAdvertizerImage";

    return this.post<any>(url, formData);
  }

  public UploadLeagueLogoImage(file : File, group_id: number){
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('group_id', group_id.toString());
    let url = API_URL + "UploadLeagueLogoImage";

    return this.post<any>(url, formData);
  }

  public UploadEmailImage(file: File, group_id: number){
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let url = API_URL + "UploadLeagueLogoImage/"+group_id;

    return this.post<any>(url, formData);
  }


}
