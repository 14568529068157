import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component,   OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Group } from 'src/app/objects/group';
import { GroupSettings } from 'src/app/objects/group-settings';
import { PlayerService } from 'src/app/services/player.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'wagl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterContentChecked {
  @ViewChild('loginMenuTemplate') loginMenuTemplate!: TemplateRef<any>;
  @ViewChild('playerMenuTemplate') playerMenuTemplate!: TemplateRef<any>;

  @ViewChild('leagueMenu') leagueMenu: MatMenu;

  //currentTemplate: TemplateRef<any> | null = null;
  isSmallScreen: boolean= false;
  leagues: Group[] = [];
  currentGroup: GroupSettings | null = null;

  constructor(
    private playerService: PlayerService,
    private sessionService: SessionService,
    private responsiveService: ResponsiveService,
    private changeDedectionRef: ChangeDetectorRef) 
  {
      this.responsiveService.isSmallScreen$.subscribe((isSmallScreen) => {
        this.isSmallScreen = isSmallScreen;
      });
  }

  ngOnInit() {
    this.sessionService.currentGroup$
      .subscribe( (value) => {
        console.log(value);
        this.currentGroup = value;
      });
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  get currentTemplate(){
    var template = this.loginMenuTemplate;
    var player = this.playerService.getLoggedInPlayerInfo();
    if (player !== null){
      template = this.playerMenuTemplate;
      this.leagues = this.playerService.getCachedLeagues(player.player_id);
    }
    return template;
  }

  trackList(index: any, group: Group){
    return group? group.group_id: undefined;
  }
}
