export class GroupSettings {
    group_id: number;
    group_type: number;
    group_name: string;
    player_id: number;
    nbr_of_ads: number;
    logo_url: string;
    icon_url: string;
    usga_club_id: number;
    rel_ads_url: string;
    expiration_date: string;
    net_skin_percent: number;
    handicap_scheme: number;
    use_custom_hcp: number;
    keep_handicap: number;
    handicap_day: number;
    registration_deadline: string;
    scorecard_playoffs: string;
    self_registration: string;
    active_season: number;
    next_season: number;
    half_skins: string;
    external_id_as_ghin: string;
    max_foursome: number;
    league_type: string;
    season_name: string;
    season_year: string;
    season_start_date: string;
    season_end_date: string;  
    season_status: string;
    season_fees: number;
    membership_level_id: number;
    quota_season: string;
    nbr_best_of_quota_rounds: number;
    stripe_subscription_id: string;
    cancelled: number;
    stripe_connect_user_id: string;
    is_usga_club: string;
    net_stroke_play_percent: number;
    nbr_of_tournament_rounds: number;
    use_3_5: number;
    do_not_use_slope: number;
    max_strokes: number;
    is_private: number;
    is_dirty: boolean;

    constructor(){
        
    }

    // Static method to create an instance of GroupSettings from a JSON object
    static fromJson(json: any): GroupSettings {
        const groupSettings = new GroupSettings();

        groupSettings.group_id = Number(json.group_id);
        groupSettings.group_type = Number(json.group_type);
        groupSettings.group_name = String(json.group_name);
        groupSettings.player_id = Number(json.player_id);
        groupSettings.nbr_of_ads = Number(json.nbr_of_ads);
        groupSettings.logo_url = String(json.logo_url);
        groupSettings.icon_url = String(json.icon_url);
        groupSettings.usga_club_id = Number(json.usga_club_id);
        groupSettings.rel_ads_url = String(json.rel_ads_url);
        groupSettings.expiration_date = String(json.expiration_date);
        groupSettings.net_skin_percent = Number(json.net_skin_percent);
        groupSettings.handicap_scheme = Number(json.handicap_scheme);
        groupSettings.use_custom_hcp = Number(json.use_custom_hcp);
        groupSettings.keep_handicap = Number(json.keep_handicap);
        groupSettings.handicap_day = Number(json.handicap_day);
        groupSettings.registration_deadline = String(json.registration_deadline);
        groupSettings.scorecard_playoffs = String(json.scorecard_playoffs);
        groupSettings.self_registration = String(json.self_registration);
        groupSettings.active_season = Number(json.active_season);
        groupSettings.next_season = Number(json.next_season);
        groupSettings.half_skins = String(json.half_skins);
        groupSettings.external_id_as_ghin = String(json.external_id_as_ghin);
        groupSettings.max_foursome = Number(json.max_foursome);
        groupSettings.league_type = String(json.league_type);
        groupSettings.season_name = String(json.season_name);
        groupSettings.season_year = String(json.season_year);
        groupSettings.season_start_date = String(json.season_start_date);
        groupSettings.season_end_date = String(json.season_end_date);
        groupSettings.season_status = String(json.season_status);
        groupSettings.season_fees = Number(json.season_fees);
        groupSettings.membership_level_id = Number(json.membership_level_id);
        groupSettings.quota_season = String(json.quota_season);
        groupSettings.nbr_best_of_quota_rounds = Number(json.nbr_best_of_quota_rounds);
        groupSettings.stripe_subscription_id = String(json.stripe_subscription_id);
        groupSettings.cancelled = Number(json.cancelled);
        groupSettings.stripe_connect_user_id = String(json.stripe_connect_user_id);
        groupSettings.is_usga_club = String(json.is_usga_club);
        groupSettings.net_stroke_play_percent = Number(json.net_stroke_play_percent);
        groupSettings.nbr_of_tournament_rounds = Number(json.nbr_of_tournament_rounds);
        groupSettings.use_3_5 = Number(json.use_3_5);
        groupSettings.do_not_use_slope = Number(json.do_not_use_slope);
        groupSettings.max_strokes = Number(json.max_strokes);
        groupSettings.is_private = Number(json.is_private);
        groupSettings.is_dirty = Boolean(json.is_dirty);

        return groupSettings;
    }
}