import { Component, OnInit } from '@angular/core';
import { catchError, finalize, of } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { StripeService } from 'src/app/services/stripe.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'wagl-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit{
  paymentHandler: any = null;
  paymentProcessing: boolean = false;
  message: string = '';
  
  constructor(
    private stripeService: StripeService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
  }
  
  
  makePayment(amount: number, name: string, desc: string){
    this.paymentProcessing = true;
    this.message = 'Verifying Card ...';
    this.stripeService.generateToken(amount, name, desc);
    this.stripeService.paymentToken$
      .subscribe( (newToken) => {
        //do the purchase stuff here
        if (newToken !== null && newToken !== undefined){
          console.log(newToken);
          newToken.price = amount *100;
          this.processCcResponse(newToken);
        }else{
          this.message = 'Payment Processor down :(';
        }

      });
  }

  processCcResponse(token: any){
      this.eventService.payEventEntryFee(token.id, token.price, "test event", "48", "7443")
        .pipe(
          catchError( (err) => {
            console.log(err);
            return of(null)
          }),
          finalize( () => {
            this.paymentProcessing = false;
          })
        )
        .subscribe(resp => {
          var response = JSON.parse(resp.d).response;
          console.log(response);
          if (response.status === 'failed') {
            this.message = response.message;
          }else{
            this.message = "Success";
          }
        });
  }


  
  
}
