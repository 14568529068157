import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

const API_URL = environment.BASE_API_URL + 'GGKCourseWebService.asmx';
const GGK_API_KEY = environment.GGK_API_KEY;

@Injectable()
export class CoursesService {

  
  constructor(
    private http: HttpClient
  ) { }

  post<T>(url : string, payload: any){
    return this.http.post<T>(url, payload, {params : {'api_key' : GGK_API_KEY }})
  }

  getFilteredCourses(filter: string, startIndex: number, count: number){
    let payload = { filter: filter, startIndex: startIndex, count: count };
    
    let url = API_URL + '/getFilteredCourses';

    return this.post<any>(url, payload);
  }

  getCourseScorecard(course_id: string){
    let payload = { course_id : course_id};
    let url = API_URL + "/getCourseScoreCard";

    return this.post<any>(url, payload);
  }

  requestCourseRevision(course_id: string){
    let payload = { course_id : course_id};
    let url = API_URL + "/requestCourseRevision";

    return this.post<any>(url, payload);
  }

  saveCourseUpdates(scorecard: any[]){
    let payload = {scorecardStr : JSON.stringify(scorecard)};
    let url = API_URL + "/saveCourseUpdates";

    return this.post<any>(url, payload);
  }

  searchUsgaCourse(state: string, courseName: string){
    let payload = { state: state, courseName: courseName };
    let url = API_URL + "/searchUsgaCourse";

    return this.post<any>(url, payload);
  }

  updateUsgaCourseLookup(ggk_course_id: string, usga_facility_id : number, usga_course_id: number){
    let payload = { ggk_course_id: ggk_course_id, usga_facility_id: usga_facility_id, usga_course_id: usga_course_id  };
    let url = API_URL + "/updateUsgaCourseLookup";

    return this.post<any>(url, payload);
  }

  syncUsgaTeeSet(usga_course_id: number, ggk_course_id: string){
    let payload = { ggk_course_id: ggk_course_id, usga_course_id: usga_course_id  };
    let url = API_URL + "/syncUsgaTeeSet";

    return this.post<any>(url, payload);
  }

  copyUsgaCourse(session_id : string){
    let payload = { session_id: session_id };
    let url = API_URL + "/copyUsgaCourse";

    return this.post<any>(url, payload);
  }


  
  updateUsgaCourse(session_id : string){
    let payload = { session_id: session_id };
    let url = API_URL + "/updateUsgaCourse";

    return this.post<any>(url, payload);
  }

  getUsgaCourseByState(state: string){
    let payload = { state: state };
    let url = API_URL + "/getUsgaCourseByState";

    return this.post<any>(url, payload);
  }

  addCourseFromUsgaCourseId(usga_course_id: number){
    let payload = { usga_course_id: usga_course_id };
    let url = API_URL + "/addCourseFromUsgaCourseId";

    return this.post<any>(url, payload);
  }

  searchGgkCourse(state:string, name:string, zip:string, city:string, course_id:string) {
    let payload = { 
      state: state == null ? '' : state,
      name: name == null ? '' : name,
      zip: zip == null ? '' : zip,
      city: city == null ? '' : city,
      course_id: course_id == null ? '' : course_id
     };
    
    let url = API_URL + "/searchGgkCourse";

    return this.post<any>(url, payload);
  }

  getUsgaAndGgkTees(ggk_course_id: string, usga_course_id: number){
    let payload = { ggk_course_id: ggk_course_id, usga_course_id: usga_course_id };
    let url = API_URL + "/getUsgaAndGgkTees";

    return this.post<any>(url, payload);
  }

  getUsgaStates(_player_id: string) {
    let payload = { aString: _player_id };
    let url = API_URL + '/getUsgaStates';

    return this.post<any>(url, payload);
  }

  getStates(_player_id: string | undefined) {
    let payload = { aString: _player_id };
    let url = API_URL + '/getStates';

    return this.post<any>(url, payload);
  }

  getCoursesForState(_state: string) {
    let payload = { state: _state };
    let url = API_URL + '/getCoursesForState';

    return this.post<any>(url, payload);
  }

  getTeesForCourse(_course_id: string) {
    let payload = { course_id: _course_id };
    let url = API_URL + '/getTeesForCourse';

    return this.post<any>(url, payload);
  }
}
