import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

const API_URL = environment.DOC_API_URL;
const GGK_API_KEY = environment.GGK_API_KEY;

@Injectable()
export class DocumentService {

  
  constructor(
    private http: HttpClient
  ) { }

  post<T>(url : string, payload: any){
    let options = { 
      responseType: 'text' as 'json',
      params : {'api_key' : GGK_API_KEY } 
    };

    return this.http.post<T>(url, payload, options)
  }

  ExportExcelPairings(group_event_id: string) {
    let payload = { group_event_id: group_event_id };

    let url = API_URL + 'ExportExcelPairings';

    return this.post<any>(url, payload);
  }

  ExportExcelPairingsQuota(group_event_id: string, scoring: string) {
    let payload = { group_event_id: group_event_id, scoring: scoring };

    let url = API_URL + 'ExportExcelPairingsQuota';

    return this.post<any>(url, payload);
  }

  ExportExcelAlpha(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    
    let url = API_URL + 'ExportExcelAlpha';

    return this.post<any>(url, payload);
  }

  generateLabels(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    
    let url = API_URL + 'generateLabels';

    return this.post<any>(url, payload);
  }

  generateCartSigns2up(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    
    let url = API_URL + 'generateCartSigns2up';

    return this.post<any>(url, payload);
  }

  generateCartSigns(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    
    let url = API_URL + 'generateCartSigns';

    return this.post<any>(url, payload);
  }

  generate4PlayerScorecard(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    
    let url = API_URL + 'generate4PlayerScorecard';

    return this.post<any>(url, payload);
  }

  generate2PlayerScorecard(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    
    let url = API_URL + 'generate2PlayerScorecard';

    return this.post<any>(url, payload);
  }

  generateScorecards(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    
    let url = API_URL + 'generateScorecards';

    return this.post<any>(url, payload);
  }



}
