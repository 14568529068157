import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Group } from 'src/app/objects/group';
import { Player } from 'src/app/objects/player';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'wagl-my-groups',
  templateUrl: './my-groups.component.html',
  styleUrls: ['./my-groups.component.scss']
})
export class MyGroupsComponent implements OnInit {
  private player : Player;
  public groups : Group[];
  dataSource: MatTableDataSource<Group>;
  displayedColumns: string[] = ['logo', 'group_name'];
  loading = false;

  private group_types = '0,1,2,3,4,5';


  constructor(
    private localStorage : LocalStorageService,
    private playerService : PlayerService,
    private router: Router
  ) { }

  ngOnInit() {
    console.log("MyGroupsComponent");
    this.player = this.localStorage.get("playerInfo");
    console.log(this.player);
    if ( !this.player){
      this.router.navigateByUrl('');
      return;
    }
    this.fetchGroups();
  }

  fetchGroups(){
    this.loading = true;
    this.playerService.getGroups(this.player.player_id.toString(), this.group_types)
      .subscribe(response => {
        this.loading = false;
        this.loadGroups(response);
      });
  }

  loadGroups(response : any): void {
    this.groups = JSON.parse(response.d).groups;

    if (!Array.isArray(this.groups)){
      this.groups = Array.of(this.groups);
    }
    this.dataSource = new MatTableDataSource(this.groups);
    this.groups.sort( (a,b) => {
      return b.group_type - a.group_type;
    })
    console.log(this.groups);
  }

  getIconName(group_type: number){
    var icon ='';
    switch (group_type)
    {
      case 0:
        icon = 'person';
        break;
      case 1:
        icon = 'groups';
        break;
      case 2:
        icon = 'trophy';
        break;
      case 3:
        icon = 'card_travel';
        break;
    }
    return icon;
  }

}
