<div class="component">
Header
<h1>HEADER 1</h1>
<h2>header 2</h2>
<h3>header 3</h3>
<h4>header 4</h4>

<div>
Large Button (primary color)<br/>
<button class="wagl_button_large" mat-raised-button color="primary">hello</button>
<button class="wagl_button_large" mat-raised-button color="primary" disabled>hello</button>
<button class="wagl_button_large" mat-stroked-button color="primary">hello</button>
<button class="wagl_button_large" mat-button color="primary">hello</button>
<br/>
</div>
<div>
Large Button (accent color)<br/>
<button class="wagl_button_large" mat-raised-button color="accent">hello</button>
<button class="wagl_button_large" mat-raised-button color="accent" disabled>hello</button>
<button class="wagl_button_large" mat-stroked-button color="accent">hello</button>
<button class="wagl_button_large" mat-button color="accent">hello</button>
<br/>
</div>

<div>
Large Button (warn color)<br/>
<button class="wagl_button_large" mat-raised-button color="warn">hello</button>
<button class="wagl_button_large" mat-raised-button color="warn" disabled>hello</button>
<button class="wagl_button_large" mat-stroked-button color="warn">hello</button>
<button class="wagl_button_large" mat-button color="warn">hello</button>
<br/>
</div>

<div>
Medium Large Button<br/>
<button class="wagl_button_medium_large" mat-raised-button color="primary">hello</button>
<button class="wagl_button_medium_large" mat-raised-button color="primary" disabled>hello</button>
<button class="wagl_button_medium_large" mat-stroked-button color="primary">hello</button>
<button class="wagl_button_medium_large" mat-button color="primary">hello</button>
<br/>
</div>

Small Button<br/>
<button class="wagl_button_small" mat-raised-button color="primary">hello</button>
<button class="wagl_button_small" mat-raised-button color="primary" disabled>hello</button>
<button class="wagl_button_small" mat-stroked-button color="primary">hello</button>
<button class="wagl_button_small" mat-button color="primary">hello</button>
<br/>

Icon buttons<br/>
<button class="wagl_button_square" mat-icon-button color="primary"><mat-icon>list</mat-icon></button>
<button class="wagl_button_square" mat-icon-button color="accent" disabled><mat-icon>list</mat-icon></button>
<button class="wagl_button_square" mat-icon-button color="warn"><mat-icon>list</mat-icon></button>
<button class="wagl_button_square" mat-icon-button><mat-icon>list</mat-icon></button>

<br/>
FAB buttons<br/>
<button class="wagl_button_square" mat-fab color="primary"><mat-icon>list</mat-icon><br/>
  List</button>
<button class="wagl_button_square" mat-fab color="accent" disabled><mat-icon>list</mat-icon><br/>
  List</button>
<button class="wagl_button_square" mat-fab color="warn"><mat-icon>list</mat-icon><br/>
  List</button>
<button class="wagl_button_square" mat-fab><mat-icon>list</mat-icon><br/>
  List</button>
</div>