import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Player } from 'src/app/objects/player';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'wagl-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  email: "";
  password: "";
  error : string;
  // socialUser: SocialUser;
  sendingRequest = false;
  loginWithEmail: boolean = true;
  loginWithGoogle: boolean = false;
  loginWithFacebook: boolean = false;

  constructor(
    public dialog: MatDialog,
    private playerService: PlayerService,
    private router: Router,
    private dialogRef: MatDialogRef<LoginDialogComponent>
  ) { 
    let player = this.playerService.getLoggedInPlayerInfo();
    if ( player ){
      this.dialogRef.close(true);
      this.router.navigateByUrl("/dashboard");
    }
  }

  ngOnInit() {
  }

  onLogin(): void {
    if ( !this.email || !this.password) {
      this.error = "Email and Password are required.";  
      return;        
    }
    this.error = '';
    this.sendingRequest = true;
    this.playerService.login(this.email,this.password).subscribe(
      response => {
        this.loadApp(response)
      },
      error => {
        this.error='Login attempt failed, please try again.';
        this.sendingRequest = false;
      }
    )
  }

  onNoClick(){
    this.dialogRef.close(false);
    this.router.navigateByUrl("/");
  }

  loadApp(response: any): void {
    this.sendingRequest = false;
    var player = JSON.parse(response.d);
    this.playerService.setLoggedInPlayerInfo(player[0]);
    if ( player[0] ){
      this.dialogRef.close(true);
      this.loadAdminGroups(player[0]);
      return;
    }
    this.error = "Login attempt failed, please try again.";
  }

  loadAdminGroups(player: Player){
    this.playerService.loadAdminGroups(player.player_id.toString());
    //this.router.navigateByUrl("dashboard/2");
  }

  forwardToCreate(){
    this.dialogRef.close(false);
    this.router.navigateByUrl("/register");
  }
}
