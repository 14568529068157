<section id='membership-options'>
  <div class='bg2' style='padding-top:80px;padding-bottom:100px;height: 550px;' fxHide.xs>
      <h1 style='text-align: center'>Enterprise Software</h1>
  </div>
  <div class='mobile-bg' fxShow.xs fxHide.gt-xs>
  </div>
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around start" fxLayoutalign.xs="center center">
      <div fxFlex="45" fxFlex.xs="100" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start stretch"
          fxLayoutAlign.xs="center center" style="min-height: 400px;" fxLayoutGap.xs="10px">
          <div class="enterprise" fxHide.xs fxFlex="50">
              <img src="/assets/images/leagues.jpg" height="100%" style="margin-left:-200px;max-height: 420px;">
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center" style="padding:20px;" class="enterprise"
              fxFlex="50" fxFlex.xs="100">
              <h2>Golf League Software</h2>
              <p>
                  Your group plays more then 10-12 times a year and you don't want to have to keep track of
                  emails responses, cut and paste players around, and making scorecards. Do we have the thing for you!
              </p>
              <button mat-raised-button class='wagl_button_small' color="primary" (click)='startNow()'>OWN IT</button>
          </div>
      </div>
      <div fxFlex="45" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="start stretch" style="min-height: 400px;">
          <div class="enterprise" fxHide.xs fxFlex="50">
              <img src="/assets/images/charity.jpg" height="100%" style="margin-left:-105px;max-height: 420px;">
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center" style="padding:20px;" class="enterprise"
              fxFlex="50" fxFlex.xs="100">
              <h2>Charitable or Corporate Events</h2>
              <p>
                  You run a charity or your corporate golf tournament, why bother with tracking people around.
                  Let our software do all the grunt work for you.
              </p>
              <button mat-raised-button class='wagl_button_small' color="primary" (click)='startNow()'>START TODAY</button>
          </div>
      </div>
      <div style="width:100%;padding:30px" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <img src="/assets/images/quotes-color.png" width="20px">
          <p style="width:450px;max-width:90%;text-justify: center;">
              Software was exceptional! We are once again looking forward to having your software make scoring on our
              trip a breeze
          </p>
          <b>
              - The WAB Crew
          </b>
      </div>
  </div>
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around stretch" fxLayoutAlign.xs="center center"
      fxLayoutGap.xs="10px">
      <div fxFlex="30" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="start stretch">
          <img width="100%" src="/assets/images/foursome.jpg">
          <div style="background-color:black; color:white; width:100%;height: 70px; text-align: center;">
              <h3>FOURSOME</h3>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex>

              <p style="padding:10px 40px">
                  Start with our basic membership, 4 players free for ever. Create events, keep track of bets
                  within your foursome. Upgradable to 8 players.
              </p>
              <div fxLayout="row" fxLayoutAlign="center center">
                  <button mat-raised-button class='wagl_button_small' color="primary" (click)='startNow()'>FREE?</button>
              </div>
          </div>
      </div>
      <div fxFlex="30" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="start stretch">
          <img width="100%" src="/assets/images/groups.jpg">
          <div style="background-color:black; color:white; width:100%;height: 70px; text-align: center;">
              <h3>GROUPS</h3>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex>
              <p style="padding:10px 40px">
                  Running a casual group that meets only once in a while? No real structure you but likes to play fun
                  games? Our group membership could be the thing for you.
              </p>
              <div fxLayout="row" fxLayoutAlign="center center">
                  <button mat-raised-button class='wagl_button_small' color="primary" (click)='startNow()'>I HAVE A GROUP</button>
              </div>
          </div>
      </div>
      <div fxFlex="30" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="start stretch">
          <img width="100%" src="/assets/images/trips.jpg">
          <div style="background-color:black; color:white; width:100%;height: 70px; text-align: center;">
              <h3>TRIPS</h3>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex>
              <p style="padding:10px 40px">
                  Meeting only once or twice a year and don't want to get into a monthly commitment? Our trip
                  membership is what you want!
                  Sign up for free, setup you events, only pay when you are ready to start playing.
              </p>
              <div fxLayout="row" fxLayoutAlign="center center">
                  <button mat-raised-button class='wagl_button_small' color="primary" (click)='startNow()'>GOOD TIMES</button>
              </div>
          </div>
      </div>
  </div>
  <div style="height: 20px;"></div>
</section>