import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';
import { PlayerService } from 'src/app/services/player.service';
import { GroupService } from '../services/group.service';
import { SessionService } from '../services/session.service';


@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private playerService : PlayerService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    let player = this.playerService.getLoggedInPlayerInfo();
    if (player == null){
      return false;
    }
    let routeGroupId = +route.params['groupId'];
    
    if (this.playerService.isPlayerGroupAdmin(routeGroupId)){
      return true;
    }
    
    console.log("Not Admin.");
    this.router.navigateByUrl("/unauthorized");
    return false;
  }
}
