<div mat-dialog-title fxLayout="row" fxLayoutAlign="center center" style="padding-top:20px">
  <img src='./assets/img/wagl_wordmark-red_logo_final.png' height='100px'>
</div>
<div *ngIf='loginWithEmail' style='padding-bottom:15px' fxLayout="column" fxLayoutAlign="center stretch">
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center stretch">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="email" placeholder="Email" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput [(ngModel)]="password" type="password" placeholder="Password" (keydown.enter)='onLogin()' required>
    </mat-form-field>
  </div>
  <div *ngIf="!sendingRequest" mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <button mat-raised-button color='primary' class="wagl_button_small" (click)="onLogin()" tabindex="2">login
    </button>
    <button mat-button class="wagl_button_small" (click)="onNoClick()" tabindex="-1">cancel</button>
  </div>
  <div *ngIf="sendingRequest" mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="spinner">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</div>

<!-- <button class='facebook-button' style='width:100%' mat-raised-button (click)='signInWithFB()'>
  <div fxLayout='row' fxLayoutAlign='space-between center'>
    <div>
        <img src="assets\images\facebook-white.png" width='18px'>
    </div>
    <div fxFlex>
      Login with Facebook
    </div>
    <div>
      <i class='fa fa-spinner fa-spin' *ngIf='loginWithFacebook'></i>
    </div>
  </div>
</button>
<button color='warn' style='width:100%' mat-raised-button (click)='signInWithGoogle()'>
  <div fxLayout='row' fxLayoutAlign='space-between center'>
    <div>
      <i class='fa fa-google-plus'> </i>
    </div>
    <div fxFlex>
      Login with Google
    </div>
    <div>
      <i class='fa fa-spinner fa-spin' *ngIf='loginWithGoogle'></i>
    </div>
  </div>
</button> -->
<!-- <button color='' style='width:100%' mat-raised-button (click)='loginWithEmail = !loginWithEmail'>
  <div fxLayout='row' fxLayoutAlign='space-between center'>
    <div>
      <i class='fa fa-envelope'> </i>
    </div>
    <div fxFlex>
      Login with Email
    </div>
  </div>
</button> -->
<div style='text-align: center'><p>Don't have an account?
    <a style='cursor: pointer; text-decoration-color:green; color: green' (click)='forwardToCreate()'>Create one here</a></p>
</div>

<div mat-dialog-content class="error_style" *ngIf="error">{{error}}</div>
