import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GroupSettings } from '../objects/group-settings';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  _currentGroup: BehaviorSubject<GroupSettings | null>  = new BehaviorSubject<GroupSettings | null>(new GroupSettings());
  _currentEventId: BehaviorSubject<number> = new BehaviorSubject<number>(0); 


  constructor() { }

  setCurrentGroup(group: GroupSettings | null){
    this._currentGroup.next(group);
  }

  get currentGroup$(){
    return this._currentGroup.asObservable();
  }

  setCurrentEventId(group_event_id: number){
    this._currentEventId.next(group_event_id);
  }

  get currentEventId$(){
    return this._currentEventId.asObservable();
  }

  removeCurrentGroup(){
    this.setCurrentGroup(null);
  }

}
