import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

const API_URL = environment.BASE_API_URL + 'GGKEmailWebService.asmx/';
const GGK_API_KEY = environment.GGK_API_KEY;

@Injectable()
export class MessageService {

  constructor(private http: HttpClient) { }

  post<T>(url : string, payload: any){
    return this.http.post<T>(url, payload, {params : {'api_key' : GGK_API_KEY }})
  }

  sendParticipantEmail(group_event_id: string, sender_email: string, subject:string, htmlContent: string) {
    
    let payload = { group_event_id: group_event_id,  sender_email: sender_email, subject: subject, htmlContent: htmlContent};
    let url = API_URL + 'sendParticipantEmail';

    return this.post<any>(url, payload);
  }

  sendGroupEmail(group_id: number, sender_email: string, subject:string, htmlContent: string) {
    
    let payload = { group_id: group_id,  sender_email: sender_email, subject: subject, htmlContent: htmlContent};
    let url = API_URL + 'sendGroupEmail';

    return this.post<any>(url, payload);
  }

  sendPlayerEmail(player_id: string, sender_email: string, subject:string, htmlContent: string) {
    
    let payload = { player_id: player_id,  sender_email: sender_email, subject: subject, htmlContent: htmlContent};
    let url = API_URL + 'sendPlayerEmail';

    return this.post<any>(url, payload);
  }

  sendEventEmail(group_event_id: string, subject: string){
    let payload = { group_event_id: group_event_id,  subject: subject };
    let url = API_URL + 'sendEventEmail';

    return this.post<any>(url, payload);
  }

  sendAddedToEvent(group_event_id: string, player_ids: string){
    let payload = { group_event_id: group_event_id,  player_ids: player_ids };
    let url = API_URL + 'sendAddedToEvent';

    return this.post<any>(url, payload);
  }

  sendEventCompletedEmail(group_event_id: string){
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'sendResultsEmail';

    return this.post<any>(url, payload);
  }

  sendTeeTimesEmail(group_event_id: string){
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'sendTeeTimesEmail';

    return this.post<any>(url, payload);
  }

  sendPushNotifications(playerIdList: string, sender: string, message:string) {
    
    let payload = { playerIdList: playerIdList,  sender: sender, message: message};
    let url = API_URL + 'sendPushNotifications';

    return this.post<any>(url, payload);
  }
  
  contactEmail(email: string){
    let payload = { email: email };
    let url = API_URL + 'contactEmail';

    return this.post<any>(url, payload);
  }

  sendRegisterGuestRequest(group_id: number, group_event_id:string, event_desc:string, sender_player_id:string, guest_email:string){
    let payload = {
      group_id : group_id,
      group_event_id : group_event_id,
      event_desc : event_desc,
      sender_player_id: sender_player_id,
      guest_email: guest_email
    }
    let url = API_URL + 'sendRegisterGuestRequest';

    return this.post<any>(url, payload);
  }

  sendPairingRequest(group_id: number, group_event_id:string, event_desc:string, sender_player_id:string, requests:string){
    let payload = {
      group_id : group_id,
      group_event_id : group_event_id,
      event_desc : event_desc,
      sender_player_id: sender_player_id,
      requests: requests
    }
    let url = API_URL + 'sendPairingRequest';

    return this.post<any>(url, payload);
  }
  
}
