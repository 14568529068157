import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { GroupSettings } from '../objects/group-settings';
import { LocalStorageService } from 'angular-2-local-storage';
import { Group } from '../objects/group';
import { LedgerEntry } from 'src/app/objects/ledger-entry';
import { GroupPlayer } from '../objects/group-player';
import { LeagueSeason } from '../objects/league_season';
import { GroupEvent, GroupEventRequest } from '../objects/group-event';


const API_URL = environment.BASE_API_URL + 'GGKGroupWebService.asmx/';
const GGK_API_KEY = environment.GGK_API_KEY;

@Injectable()
export class GroupService {

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) { }
  
  post<T>(url : string, payload: any){
    return this.http.post<T>(url, payload, {params : {'api_key' : GGK_API_KEY }});
  }

  get<T>(url : string){
    return this.http.post<T>(url, {params : {'api_key' : GGK_API_KEY }});
  }

  setAdminGroups(groups: Group[]){
    this.localStorage.set('adminGroups', groups);
  }

  getAdminGroups() : Group[] {
    return this.localStorage.get('adminGroups');
  }

  getGroupAdmins(group_id: number){
    let payload = { group_id: group_id };
    let url = API_URL + 'getGroupAdmins';
    
    return this.post<any>(url, payload);
  }

  addPlayerToGroup(group_id: number, first: string, last: string, email: string, handicap : string) : Observable <any> {
    let payload = { group_id: group_id, first: first, last: last, email: email, handicap : handicap };
    let url = API_URL + 'addPlayerToGroup';
    
    return this.post<any>(url, payload);
  }

  addPlayerToGroupWithGhin(group_id: number, first: string, last: string, email: string, handicap : string, ghin_id: string) : Observable <any> {
    let payload = { group_id: group_id, first: first, last: last, email: email, handicap : handicap, ghin_id: ghin_id };
    let url = API_URL + 'addPlayerToGroupWithGhin';
    
    return this.post<any>(url, payload);
  }

  upgradeMembership(groupId: string, newMembershipId: string, stripeSubscriptionId: string){
    let payload = { groupId: groupId, newMembershipId: newMembershipId, stripeSubscriptionId: stripeSubscriptionId };
    let url = API_URL + 'upgradeMembership';
    
    return this.post<any>(url, payload);
  }

  checkPromoCode(code: string){
    let payload = { promoCode: code};
    let url = API_URL + 'checkPromoCode';

    return this.post<any>(url, payload);
  }

  cancelMembership(groupId: string, playerId: string, stripeSubscriptionId: string){
    let payload = { groupId: groupId, playerId: playerId, stripeSubscriptionId: stripeSubscriptionId };
    let url = API_URL + 'cancelMembership';
    
    return this.post<any>(url, payload);
  }

  reactivateMembership(groupId: string, playerId: string, stripeSubscriptionId: string){
    let payload = { groupId: groupId, playerId: playerId, stripeSubscriptionId: stripeSubscriptionId };
    let url = API_URL + 'reactivateMembership';
    
    return this.post<any>(url, payload);
  }

  createLeagueOrTrip(playerId: string, name: string, type: string, seasonStart: string, seasonEnd: string){
    let payload = { playerId: playerId, name: name, type: type, seasonStart: seasonStart, seasonEnd: seasonEnd};
    let url = API_URL + 'createLeagueOrTrip';
    
    return this.post<any>(url, payload);
  }

  getGroupSeason(groupId: string){
      let payload = { groupId: groupId};
      let url = API_URL + 'getGroupSeason';
      
      return this.post<any>(url, payload);
  }

  getActiveSeason(group_id: number){
    let payload = { group_id: group_id};
    let url = API_URL + 'getActiveSeason';
    
    return this.post<any>(url, payload);
  }
  
  getSeasonInfo(group_id: number, season_id:string){
    let payload = { group_id: group_id, season_id: season_id};
    let url = API_URL + 'getSeasonInfo';
    
    return this.post<any>(url, payload);
  } 
  
  getSubscriptionInfo(player_id: string, subscription_id : string){
    let payload = { player_id : player_id, subscription_id: subscription_id};
    let url = API_URL + 'GetSubscriptionInfo';

    return this.post<any>(url, payload);
  }

  GetStripeCustomer(customer_id: string){
    let payload = { customer_id : customer_id };
    let url = API_URL + 'GetStripeCustomer';

    return this.post<any>(url, payload);
  }

  
  UpdateSubscriptionPaymentMethod(subscription_id: string, player_id: string, token : string){
    let payload = { subscription_id : subscription_id, player_id : player_id, token: token };
    let url = API_URL + 'UpdateSubscriptionPaymentMethod';

    return this.post<any>(url, payload);
  }
  
  UpdatePaymentMethod(customer_id: string, token : string){
    let payload = { customer_id : customer_id, token: token };
    let url = API_URL + 'UpdatePaymentMethod';

    return this.post<any>(url, payload);
  }

  getPaymentMethod(customer_id: string){
    let payload = { customer_id : customer_id };
    let url = API_URL + 'getPaymentMethod';

    return this.post<any>(url, payload);
  }

  createGroup(playerId: string, name: string, type: string){
    let payload = { playerId: playerId, groupName: name, groupType: type};
    let url = API_URL + 'createGroup';
    
    return this.post<any>(url, payload);
  }
  
  deleteGroup(groupId: string){
    let payload = { groupId: groupId};
    let url = API_URL + 'deleteGroup';
    
    return this.post<any>(url, payload);
  }
  
  removePlayerFromGroup(group_id: number, player_id: string | null){
    let payload = { group_id: group_id, player_id: player_id };
    let url = API_URL + 'removePlayerFromGroup';
    
    return this.post<any>(url, payload);
  }

  addPlayerToGroupFromPlayerId(group_id: number, player_id: string){
    let payload = { group_id: group_id, player_id: player_id };
    let url = API_URL + 'addPlayerToGroupFromPlayerId';
    
    return this.post<any>(url, payload);
  }

  getGroupSettings( group_id: number) : Observable <any>{
    let payload = { group_id: group_id };
    let url = API_URL + 'getGroupSettings';

    return this.post<any>(url, payload);
  }

  getLeagueSponsors( group_id: number) : Observable <any>{
    let payload = { group_id: group_id };
    let url = API_URL + 'getLeagueSponsors';

    return this.post<any>(url, payload);
  }

  getGroupPlayers( group_id: number) : Observable <any>{
    let payload = { group_id: group_id };
    let url = API_URL + 'getGroupPlayers';

    return this.post<any>(url, payload);
  }

  setGroupPlayerAsAdmin( group_id: number, player_id: string) : Observable <any>{
    let payload = { group_id: group_id, player_id: player_id };
    let url = API_URL + 'setGroupPlayerAsAdmin';

    return this.post<any>(url, payload);
  }

  setPlayerActiveSeason( group_id: number, player_id: string, active_season: string) : Observable <any>{
    let payload = { group_id: group_id, player_id: player_id, active_season: active_season };
    let url = API_URL + 'setPlayerActiveSeason';

    return this.post<any>(url, payload);
  }

  removeGroupAdmin( group_id: number, player_id: number) : Observable <any>{
    let payload = { group_id: group_id, player_id: player_id };
    let url = API_URL + 'removeGroupAdmin';

    return this.post<any>(url, payload);
  }

  setAsGroupOwner( group_id: number, player_id: number) : Observable <any>{
    let payload = { group_id: group_id, player_id: player_id };
    let url = API_URL + 'setAsGroupOwner';

    return this.post<any>(url, payload);
  }

  saveGroupSettings(payload : GroupSettings) : Observable<any> {
    let url = API_URL + 'saveGroupSettings';

    return this.post<any>(url, payload);
  }

  saveMembershipFees(season_id: string, fees_in_cent: number) : Observable<any> {
    let payload = { season_id: season_id, fees_in_cent: fees_in_cent}
    let url = API_URL + 'saveMembershipFees';

    return this.post<any>(url, payload);
  }

  saveMaxHcp(group_id: number, max_strokes: number){
    let payload = { group_id: group_id, max_strokes: max_strokes};
    let url = API_URL + 'saveMaxHcp';

    return this.post<any>(url, payload);
  }

  getGroupEventYears(group_id: number){
    let payload = { group_id: group_id};
    let url = API_URL + 'getGroupEventYears';

    return this.post<any>(url, payload);
  }

  saveCustomHandicapSchemes(group_id: number, scheme_data: string){
    let payload = { group_id: group_id, scheme_data: scheme_data};
    let url = API_URL + 'saveCustomHandicapSchemes';

    return this.post<any>(url, payload);
  }

  getHandicapSchemes(group_id: number){
    let payload = { group_id: group_id};
    let url = API_URL + 'getAvailableHandicapSchemes';

    return this.post<any>(url, payload);
  }

  fetchGroupAvgRounds(group_id: number, player_id: string){
    let payload = {group_id: group_id, player_id: player_id};
    let url = API_URL +'fetchGroupAvgRounds';

    return this.post<any>(url, payload);
  }

  getGroupRounds(group_id: number, player_id: string){
    let payload = {group_id: group_id, player_id: player_id};
    let url = API_URL +'getGroupRounds';

    return this.post<any>(url, payload);
  }

  saveGroupPlayerEmail(group_id: number, player_id: string, email: string){
    let payload = {group_id: group_id, player_id: player_id, email: email};
    let url = API_URL +'saveGroupPlayerEmail';

    return this.post<any>(url, payload);
  }

  savePlayerGhin(group_id: number, player_id: string, ghin: string){
    let payload = {group_id: group_id, player_id: player_id, ghin: ghin};
    let url = API_URL +'savePlayerGhin';

    return this.post<any>(url, payload);
  }

  updateGroupIndexFromGhin(group_id: number, player_id: string, ghin: string){
    let payload = {group_id: group_id, player_id: player_id, ghin: ghin};
    let url = API_URL +'updateGroupIndexFromGhin';

    return this.post<any>(url, payload);
  }
  
  updateGroupPlayer(payload: GroupPlayer){
    let url = API_URL +'updateGroupPlayer';

    return this.post<any>(url, payload);
  }

  addGroupPlayer(payload: GroupPlayer){
    let url = API_URL +'waglAddGroupPlayer';

    return this.post<any>(url, payload);
  }

  sendWelcomeToGroupEmail(group_id: number, player_id:string){
    let payload = {group_id: group_id, player_id: player_id};
    let url = API_URL +'sendWelcomeToGroup';

    return this.post<any>(url, payload);
    
  }

  getGroupPlayerProfile(group_id: number, player_id: string){
    let payload = {group_id: group_id, player_id: player_id};
    let url = API_URL +'getGroupPlayerProfile';

    return this.post<any>(url, payload);
  }
  
  getGroupTournamentRounds(group_id: number, player_id: string){
    let body = { group_id: group_id, player_id : player_id};
    let _url = API_URL + 'getGroupTournamentRounds';

    return this.post<any>(_url, body);
  }

  requestClientAuthentication( code: string, player_id: string, group_id: number){
    let body = { code:code, group_id: group_id, player_id : player_id};
    let _url = API_URL + 'requestClientAuthentication';

    return this.post<any>(_url, body);
  }

  createChargeForCustomer(token: string, amount: number, note: string, group_id: number, player_id: string){
    let body = { token:token, amount: amount, note : note, group_id : group_id, player_id : player_id};
    let _url = API_URL + 'CreateChargeForCustomer';

    return this.post<any>(_url, body);
  }

  recalculateGroupIndex(group_id: number){
    let payload = { group_id: group_id};
    let url = API_URL + 'recalculateGroupIndex';

    return this.post<any>(url, payload);
  }

  clearGroupStandingsCache(group_id: number, season: string){
    let payload = { groupId: group_id, season: season};
    let url = API_URL + 'clearGroupStandingsCache';

    return this.post<any>(url, payload);
  }

  getNetGroupStandings(group_id: number, season: string){
    let payload = { groupId: group_id, season: season};
    let url = API_URL + 'getNetGroupStandings';

    return this.post<any>(url, payload);
  }

  forceRecalculateQuotaLeagueStandings(group_id: number, season: string){
    let payload = { groupId: group_id, season: season};
    let url = API_URL + 'forceRecalculateQuotaLeagueStandings';

    return this.post<any>(url, payload);
  }

  getQuotaLeagueStandings(group_id: number, season: string){
    let payload = { groupId: group_id, season: season};
    let url = API_URL + 'getQuotaLeagueStandings';

    return this.post<any>(url, payload);
  }

  getGrossGroupStandings(group_id: number, season: string){
    let payload = { groupId: group_id, season: season};
    let url = API_URL + 'getGrossGroupStandings';

    return this.post<any>(url, payload);
  }

  checkIfExpired(group_id: number){
    let payload = { group_id: group_id};
    let url = API_URL + 'checkIfExpired';

    return this.post<any>(url, payload);
  }

  addChallengeHole(group_id : number,  group_event_id : string,  season : string,  hole : string){
    let payload = { group_id: group_id, group_event_id: group_event_id, season: season, hole: hole};
    let url = API_URL + 'addChallengeHole';

    return this.post<any>(url, payload);
  }
  getChallengePageData( group_id: number, season: string, is_admin: string){
    let payload = { group_id: group_id, season: season, is_admin: is_admin};
    let url = API_URL + 'getChallengePageData';

    return this.post<any>(url, payload);
  }

  calculateChallengeHole( group_id : number,  season : string,  uid : string){
    let payload = { group_id: group_id, season: season, uid: uid};
    let url = API_URL + 'calculateChallengeHole';

    return this.post<any>(url, payload);
  }

  removeChallengeHole(challenge_id: string, group_id: number, season: string){
    let payload = { challenge_id: challenge_id, group_id: group_id, season: season };
    let url = API_URL + 'removeChallengeHole';

    return this.post<any>(url, payload);
  }

  getSeasons(group_id:number){
    let payload = { group_id: group_id };
    let url = API_URL + 'getSeasons';

    return this.post<any>(url, payload);
  }

  clearGroupScores(group_id: number, player_id: string, from_date: string){
    let payload = { group_id: group_id, player_id: player_id, from_date: from_date };
    let url = API_URL + 'clearTeamScores';

    return this.post<any>(url, payload);
  }

  getQuotaRounds(groupId : number, season : string, player_id : string){
    let payload = { groupId: groupId, player_id: player_id, season: season };
    let url = API_URL + 'getQuotaRounds';

    return this.post<any>(url, payload);
  }

  getGroupByUsgaClubId(usga_club_id : number){
    let payload = { usga_club_id: usga_club_id };
    let url = API_URL + 'getGroupByUsgaClubId';

    return this.post<any>(url, payload);
  }

  getClubsForGeoLocation(latitude : string, longitude :string){
    let payload = { latitude : latitude, longitude: longitude};
    let url = API_URL + 'getClubsForGeoLocation';

    return this.post<any>(url, payload);
  }

  getClubsForZip(zip:string){
    let payload = { zip : zip};
    let url = API_URL + 'getClubsForZip';

    return this.post<any>(url, payload);
  }

  getClubsByIpAddress(){
    let url = API_URL + 'getClubsByIpAddress';

    return this.get<any>(url);
  }

  createMembershipInvite(group_id: number, player_id: number){
    let payload = { group_id: group_id, player_id: player_id};
    let url = API_URL + 'createMembershipInvite';

    return this.post<any>(url, payload);
  }

  getMembershipInvite(group_id: number, player_id: number){
    let payload = { group_id: group_id, player_id: player_id};
    let url = API_URL + 'getMembershipInvite';

    return this.post<any>(url, payload);
  }

  clearMembershipInvite(group_id: number, player_id: number){
    let payload = { group_id: group_id, player_id: player_id};
    let url = API_URL + 'clearMembershipInvite';

    return this.post<any>(url, payload);
  }


  getInvitedPlayerIds(group_id: number){
    let payload = { group_id: group_id};
    let url = API_URL + 'getInvitedPlayerIds';

    return this.post<any>(url, payload);
  }

  getPlayerLedger(group_id: number, player_id: number){
    let payload = { group_id: group_id, player_id: player_id};
    let url = API_URL + 'getPlayerLedger';

    return this.post<any>(url, payload);
  }

  getGroupLedger(group_id: number){
    let payload = { group_id: group_id };
    let url = API_URL + 'getGroupLedger';

    return this.post<any>(url, payload);
  }

  addLedgerEntry(payload: LedgerEntry){
    let url = API_URL + 'addLedgerEntry';

    return this.post<any>(url, payload);
  }

  getPlayerTotalCredits(group_id: number, player_id: string){
    let payload = { group_id: group_id, player_id: player_id};
    let url = API_URL + 'getPlayerTotalCredits';

    return this.post<any>(url, payload);
  }

  processPlayerIndexAdjustment(group_id: number, player_id: string, adjustment: string){
    let payload = { group_id: group_id, player_id: player_id, adjustment: adjustment};
    let url = API_URL + 'processPlayerIndexAdjustment';

    return this.post<any>(url, payload);
  }

  saveSeason(season: LeagueSeason){
    let payload = {league_season: season}
    let url = API_URL + 'waglSaveSeason';

    return this.post<any>(url, payload);
  }

  createNewSeason(season: LeagueSeason, group_id: number, set_as_active: boolean){
    let payload = {league_season: season, group_id: group_id, set_as_active: set_as_active}
    let url = API_URL + 'waglCreateNewSeason';

    return this.post<any>(url, payload);
  }

  activateSeason(group_id: number, season_id: string){
    let payload = {season_id: season_id, group_id: group_id}
    let url = API_URL + 'waglActivateSeason';

    return this.post<any>(url, payload);
  }

  createNewEvent(group_event: GroupEventRequest){
    let payload = {group_event: group_event}
    let url = API_URL + 'waglCreateEvent';

    return this.post<any>(url, payload);
  }

  getGroupPlayerStats(group_id: number){
      let payload = {group_id: group_id}
      let url = API_URL + 'getGroupPlayerStats';
  
      return this.post<any>(url, payload);
  }
}
