import { OnInit, Component, Input, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Group } from 'src/app/objects/group';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'wagl-league-menu',
  templateUrl: './league-menu.component.html',
  styleUrls: ['./league-menu.component.scss']
})
export class LeagueMenuComponent implements OnInit, AfterViewInit  {

  @Input() group: Group; 

  constructor(
    private playerService: PlayerService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {}

  onMouseDown(leagueMenuTrigger: MatMenuTrigger, event: any) {
    // This method can be used for any specific mouse down logic if needed.
    event.stopPropagation();
    console.log(leagueMenuTrigger);
    leagueMenuTrigger.openMenu();
    console.log(leagueMenuTrigger);
  }

  isAdmin(){
    return this.playerService.isPlayerGroupAdmin(+this.group.group_id);
  }

}
