<div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 10px">
  <ng-container [ngTemplateOutlet]="currentTemplate"></ng-container>
  <div *ngIf="currentGroup" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <img *ngIf="!isSmallScreen" src="{{currentGroup!.logo_url}}" height="80px">
    <wagl-club-header [groupSettings]="currentGroup"></wagl-club-header>
  </div>
  <div *ngIf="!isSmallScreen">
   <img src="./assets/img/wagl_wordmark-red_logo_final.png" height="30px">
  </div>
</div>

<ng-template #loginMenuTemplate>
  <div [matMenuTriggerFor]="loginMenu" style="cursor: pointer">
    <mat-icon>menu</mat-icon>
  </div>
</ng-template>

<ng-template #playerMenuTemplate>
  <div [matMenuTriggerFor]="playerMenu" style="cursor: pointer">
    <mat-icon>menu</mat-icon>
  </div>
</ng-template>

<mat-menu #loginMenu="matMenu">
  <button mat-menu-item routerLink="/login">
    <mat-icon>login</mat-icon>
    Login</button>
  <button mat-menu-item>
    <mat-icon>app_registration</mat-icon>
    Register</button>
</mat-menu>

<mat-menu #playerMenu="matMenu">
  <button mat-menu-item routerLink="/dashboard">
    <mat-icon>dashboard</mat-icon>
    Player Clubhouse
  </button>

  <ng-container *ngFor="let league of leagues; trackBy: trackList">
    <wagl-league-menu [group]="league" [attr.id]="'league'+league.group_id"></wagl-league-menu>
  </ng-container>

  <button mat-menu-item routerLink="/logout">
    <mat-icon>logout</mat-icon>
    Logout
  </button>
</mat-menu>