import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupSettings } from 'src/app/objects/group-settings';
import { GroupService } from 'src/app/services/group.service';
import { PlayerService } from 'src/app/services/player.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'wagl-club-header',
  templateUrl: './club-header.component.html',
  styleUrls: ['./club-header.component.scss']
})
export class ClubHeaderComponent implements OnInit, OnDestroy {
  @Input() groupSettings: GroupSettings;
  group_id: number;
  isSmallScreen: boolean=false;

  constructor(
    private router: Router,
    private playerService: PlayerService,
    private sessionService: SessionService,
    private responsiveService: ResponsiveService
  ) { 
    this.responsiveService.isSmallScreen$.subscribe((isSmallScreen) => {
      this.isSmallScreen = isSmallScreen;
    });
  }

  ngOnInit() {
   
  }

  ngOnDestroy(): void {
    this.sessionService.removeCurrentGroup();
  }

  getColor(icon: string): string{
    if (this.router.url.includes(icon)){
      return 'primary'
    }
    return '';
  }

  isAdmin(){
    if (this.groupSettings){
      return this.playerService.isPlayerGroupAdmin(+this.groupSettings.group_id)
    }
    return false;
  }

}
