import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  _paymentToken: Subject<any>  = new ReplaySubject<any>();


  constructor() {
   }

  get paymentToken$(){
    return this._paymentToken.asObservable();
  }

  setPaymentToken(stripeToken: any){
    this._paymentToken.next(stripeToken);
  }

  generateToken(amount: any, name: string, description: string) {
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: environment.STRIPE_KEY,
      locale: 'auto'
    });
    
    paymentHandler.open({
      name: name,
      description: description,
      amount: amount * 100,
      token: (stripeToken: any) => {
        this.setPaymentToken(stripeToken);
      }
    });
  }
}
