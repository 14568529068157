import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { StylesComponent } from './components/styles/styles.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { StripePaymentComponent } from './components/stripe-payment/stripe-payment.component';

const routes: Routes = [
  { 
    pathMatch: "full", 
    path: "", 
    component: HomeComponent
  },
  { path: "club", 
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import("./modules/club/club.module").then(m => m.ClubModule)
  },
  { path: "events", 
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import("./modules/event/event.module").then(m => m.EventModule)
  },
  { 
    pathMatch: "full", 
    path: "styles", 
    component: StylesComponent 
  },
  { 
    pathMatch: "full", 
    path: "login", 
    component: LoginComponent 
  },
  { 
    pathMatch: "full", 
    path: "logout", 
    component: LogoutComponent 
  },
  { 
    pathMatch: "full", 
    path: "stripe-test", 
    component: StripePaymentComponent
  },
  { 
    pathMatch: "full", 
    path: "unauthorized", 
    component: UnauthorizedComponent
  },
  { 
    pathMatch: "full", 
    path: "dashboard", 
    component: DashboardComponent,
    canActivate: [AuthenticatedGuard] 
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
