<h4>my memberships:</h4>
<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
  <button mat-fab *ngFor="let group of groups" color="white" matTooltip="{{group.group_name}}" routerLink="/club/home/{{group.group_id}}" fxLayout="column" fxAlign="center center">
    <img style="margin-top:5px" *ngIf="group.logo_url != '' && group.logo_url !='https://www.golfgameskeeper.com/ads/default/'" height="35" src="{{group.icon_url || group.logo_url}}">
    <div *ngIf="group.logo_url == '' ||  group.logo_url =='https://www.golfgameskeeper.com/ads/default/'">
      NL
    </div>
  </button>
</div>

<div *ngIf='loading' style='width:100%;height:500px' fxLayout='column' fxLayoutAlign='center center'>
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
