import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocalStorageService } from 'angular-2-local-storage';
import { GroupService } from './group.service';
import { Group } from '../objects/group';
import { Player } from '../objects/player';
import { PlayerOptIn } from '../objects/player-opt-in';
import { SessionService } from './session.service';



const API_URL = environment.BASE_API_URL + 'GGKPlayerWebService.asmx/';
const GGK_API_KEY = environment.GGK_API_KEY;

@Injectable()
export class PlayerService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private groupService: GroupService,
    private sessionService: SessionService
  ) {
  }
  
  post<T>(url : string, payload: any){
    return this.http.post<T>(url, payload, {params : {'api_key' : GGK_API_KEY }})
  }

  registerPlayer( email: string, first: string, last: string, handicap_index: string, password: string){
    let payload = { email: email, first: first, last:last, handicap_index: handicap_index, password: password};
    let _url = API_URL + 'registerPlayer';

    return this.post<any>(_url, payload);
  }

  registerWithEmail( email: string, first: string, last: string, handicap_index: string, password: string){
    let payload = { email: email, first: first, last:last, handicap_index: handicap_index, password: password};
    let _url = API_URL + 'registerWithEmail';

    return this.post<any>(_url, payload);
  }

  createSubscription( token: string, amount: number, frequency: number, group_id: number, player_id: string, note: string, productId: string, planId: string, couponCode: string){
    let payload = { 
      token: token,
      amount: amount, 
      frequency: frequency,
      group_id: group_id,
      player_id: player_id, 
      note: note,
      productId: productId,
      planId: planId, 
      couponCode: couponCode
    };
    let _url = API_URL + 'createSubscription';

    return this.post<any>(_url, payload);
  }

  loadAdminGroups(player_id: string){
    this.getAdminGroups(player_id.toString())
      .subscribe(resp => {
        var adminGroups : Group[] = JSON.parse(resp.d).groups;
        this.groupService.setAdminGroups(adminGroups);
      });
  }



  updatePlayer( player: Player){
    let _url = API_URL + 'updatePlayer';

    return this.post<any>(_url, player);
  }

  adminUpdatePlayer( player: Player){
    let _url = API_URL + 'adminUpdatePlayer';

    return this.post<any>(_url, player);
  }

  setNewEventDialogSeen(){
    this.localStorageService.set('newEventSeen',true);
  }

  hasNewEventDialogBeenSeen() : boolean{
    return this.localStorageService.get('newEventSeen');
  }

  login(_email: string, _password: string): Observable<any> {
    let body = { email : _email, password : _password};
    let _url = API_URL + 'login';
    return this.post<any>(_url, body);
  }

  requestPassword(email: string){  
  let body = { email : email };
  let _url = API_URL + 'requestPassword';
  return this.post<any>(_url, body);
}

  setLastLogin(){
    var today = Date.now;
    this.localStorageService.set('lastLogin', today);
  }

  getLastLogin(){
    return this.localStorageService.get('lastLogin');
  }

  accountCreatedToday(): boolean {

    var playerInfo: Player | null = this.getLoggedInPlayerInfo();
    console.log(playerInfo);
    //if ( !playerInfo ){
      //return false;
    //}
    //var created = new Date(Date.parse(playerInfo.created));
    //var today = new Date();
    //if ( created &&
     //    created.getDate() === today.getDate() &&
      //   created.getMonth() === today.getMonth() &&
        // created.getFullYear() === today.getFullYear() ){
          // return true;
    //}

    return false;
  }

  refreshPlayerInfo(){
    var playerInfo: Player | null = this.getLoggedInPlayerInfo();
    if ( !playerInfo ){
      return false;
    }
    var last_login = new Date(Date.parse(playerInfo.last_login.replace(' ','T')));
    return true;
  }

  registerWithGoogle(token: string){
    let body = { token : token};
    let _url = API_URL + 'registerWithGoogle';

    return this.post<any>(_url, body);
  }

  continueWithGoogle(token: string){
    let body = { token : token};
    let _url = API_URL + 'continueWithGoogle';

    return this.post<any>(_url, body);
  }

  logout(){
    this.sessionService.setCurrentGroup(null);
    this.localStorageService.clearAll();
  }

  getGroups (_player_id: string, _group_type: string): Observable<any> {
    let body = { player_id : _player_id, group_type : _group_type};
    let _url = API_URL + 'getGroupMemberships';

    return this.post<any>(_url, body).pipe(
        map( (data) => {
          var playerGroups : Group[] = JSON.parse(data.d).groups;
          if (!Array.isArray(playerGroups)){
            playerGroups = Array.of(playerGroups);
          }
          this.localStorageService.set('groups-'+_player_id, playerGroups);
          return data;
        })
      );
  }

  getCachedLeagues(player_id: number): Group []{
    var playerGroups : Group[] = this.localStorageService.get('groups-'+player_id);
    if (playerGroups == null){
      return [];
    }
    return playerGroups.filter(x => x.group_type === 2 );
  }

  getAdminGroups(_player_id: string){
    let body = { player_id : _player_id};
    let _url = API_URL + 'getGroups';

    return this.post<any>(_url, body);
  }

  removeGroupFromAdminGroups(group_id: number){
    var playerAdminGroups : Group[] = this.localStorageService.get('adminGroups');
    var groupIndex = playerAdminGroups.findIndex( x => x.group_id == group_id);
    playerAdminGroups.splice(groupIndex,1);
    this.localStorageService.set('adminGroups', playerAdminGroups);
  }

  getLoggedInPlayerInfo() : Player | null{
    var lastLogin = this.localStorageService.get('lastLogin') as number;
    var now = Date.now;
    var diff = +now - lastLogin;
    if ( diff > 24*60*60*1000){
      console.log("Old login. Clear all")
      this.localStorageService.clearAll();
      return null;
    }
    return this.localStorageService.get('playerInfo');
  }

  setLoggedInPlayerInfo(player: Player){
    this.setLastLogin();
    return this.localStorageService.set('playerInfo', player);
  }

  checkLoginInfo(data: Player){

  }

  isPlayerGroupAdmin(group_id: number): boolean{
    var playerAdminGroups : Group[] = this.localStorageService.get('adminGroups');
    if (!playerAdminGroups){
      return false;
    }
    var groupIndex = playerAdminGroups.findIndex( x => x.group_id == group_id);
    if ( groupIndex > -1){
      return true;
    }
    return false;
  }

  getMembershipLevels(group_type: string){
    let body = { group_type : group_type};
    let _url = API_URL + 'getMembershipLevels';

    return this.post<any>(_url, body);
  }

  getMostPlayedCourses(player_id: string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getMostPlayedCourses';

    return this.post<any>(_url, body);
  }

  getFilteredCourses(filter: string, startIndex: number, count: number){
    let payload = { filter: filter, startIndex: startIndex, count: count };
    
    let url = API_URL + 'getFilteredCourses';

    return this.post<any>(url, payload);
  }

  getFilteredCoursesByState(filter: string, startIndex: number, count: number){
    let payload = { filter: filter, startIndex: startIndex, count: count };
    
    let url = API_URL + 'getFilteredCoursesByState';

    return this.post<any>(url, payload);
  }

  getFilteredCoursesByZip(filter: string, startIndex: number, count: number){
    let payload = { filter: filter, startIndex: startIndex, count: count };
    let url = API_URL + 'getFilteredCoursesByZip';

    return this.post<any>(url, payload);
  }

  getPlayerOptInOptions(player_id: string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getPlayerOptInOptions';

    return this.post<any>(_url, body);
  }

  savePlayerOptInOptions(playerOptInOptions : PlayerOptIn){
    let _url = API_URL + 'savePlayerOptInOptions';

    return this.post<any>(_url, playerOptInOptions);
  }

  getPlayerExtras(player_id: string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getPlayerExtras';

    return this.post<any>(_url, body);
  }

  getRevisionRounds(player_id: string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getRevisionScores';

    return this.post<any>(_url, body);
  }

  getHandicapHistory(player_id:string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getHandicapHistory';

    return this.post<any>(_url, body);
  }

  getLast25Rounds(player_id:string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getLast25Rounds';

    return this.post<any>(_url, body);
  }

  getAllTournamentRounds(player_id: string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getAllTournamentRounds';

    return this.post<any>(_url, body);
  }
  
  getSubscriptionPriceList( group_type: string){
    let body = { group_type : group_type};
    let _url = API_URL + 'getSubscriptionPriceList';

    return this.post<any>(_url, body);
  }

  getUserCount(company: string){
    let body = { company : company};
    let _url = API_URL + 'getUserCount';

    return this.post<any>(_url, body);
  }

  getLastOrCurrentRound(player_id: string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getLastOrCurrentRound';

    return this.post<any>(_url, body);    
  }

  searchPlayers(searchType: string, searchText: string){
    let body = { searchType : searchType, searchText: searchText};
    let _url = API_URL + 'searchPlayers';

    return this.post<any>(_url, body);    
  }

  searchUsgaPlayers(state: string, first: string, last: string){
    let body = { state : state, first: first, last: last};
    let _url = API_URL + 'searchUsgaGolfer';

    return this.post<any>(_url, body);    
  }


  playerLookup(state: string, first: string, last: string){
    let body = { state : state, first: first, last: last};
    let _url = API_URL + 'playerLookup';

    return this.post<any>(_url, body);    
  }

  getPlayerInfo(player_id: string){
    let body = { player_id : player_id};
    let _url = API_URL + 'getPlayerInfo';

    return this.post<any>(_url, body);    
  }

  deletePlayer(player_id:string){
    let body = { player_id : player_id};
    let _url = API_URL + 'deletePlayer';

    return this.post<any>(_url, body);    
  }

  getToken(){
    var any ="any";
    let body = { any }
    let _url = API_URL + 'GetUsgaToken';

    return this.post<any>(_url, body);    
  }

  getAvailablePlans(product_name: string){
    var body = { product_name : product_name };
    let url = API_URL + "getAvailablePlans";
    return this.post<any>(url, body);
  }

  subscribeToIndividualPlan(plan_id: string, token: string, player_id: number, coupon: string, usga_club_id: number){
    var payload = { plan_id:plan_id ,token:token, player_id:player_id, coupon:coupon, usga_club_id: usga_club_id};
    let url = API_URL + "subscribeToIndividualPlan";
    return this.post<any>(url, payload);
  }
  
  reactivateSubscription(subscription_id: string){
    var payload = { subscription_id:subscription_id };
    let url = API_URL + "reactivateSubscription";
    return this.post<any>(url, payload);
  }
  
  
  cancelSubscription(subscription_id: string){
    var payload = { subscription_id:subscription_id };
    let url = API_URL + "cancelSubscription";
    return this.post<any>(url, payload);
  }

  getGolferHandicapIndex(player_id: string){
    var payload = { ggk_user_id:player_id };
    let url = API_URL + "getGolferHandicapIndex";
    return this.post<any>(url, payload);
  }

  getPastInvoices(subscription_id: string, limit: number){
    var payload = { subscription_id:subscription_id, limit : limit };
    let url = API_URL + "getPastInvoices";
    return this.post<any>(url, payload);
  }

  refetchPlayerInfo(player_id: number){
    var payload = { player_id : player_id};
    let url =  API_URL + "refetchPlayerInfo";
    return this.post<any>(url, payload);
  }

  syncRounds(player_id:number){
    let body = { player_id : player_id};
    let _url = API_URL + 'syncRounds';

    return this.post<any>(_url, body);    
  }

  getStats(player_id:string, number_of_rounds: number){
    let body = { player_id : player_id, number_of_rounds : number_of_rounds };
    let _url = API_URL + 'getStats';

    return this.post<any>(_url, body);    
  }

  getRoundStats(round_id: string){
    let body = { round_id : round_id };
    let _url = API_URL + 'getRoundStats';

    return this.post<any>(_url, body);    
  }

  removeRound(usga_round_id: number, ggk_round_id: number){
    let body = { usga_round_id : usga_round_id, ggk_round_id : ggk_round_id };
    let _url = API_URL + 'removeRound';

    return this.post<any>(_url, body);    
  }

  removeBothRound(usga_round_id: number, ggk_round_id: number){
    let body = { usga_round_id : usga_round_id, ggk_round_id : ggk_round_id };
    let _url = API_URL + 'removeBothRound';

    return this.post<any>(_url, body);    
  }
}


