import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  title = 'wagl-ui';
  paymentHandler: any = null;

  ngOnInit() {
    this.invokeStripe();
  }
    
  invokeStripe() {
    console.log("Loading Stripe");
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      
      window.document.body.appendChild(script);
    }
  }
  
}
