import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { PlayerService } from 'src/app/services/player.service';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { Player } from 'src/app/objects/player';

@Component({
  selector: 'wagl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private playerService: PlayerService,
    private localStorageService: LocalStorageService,
    private router: Router

  ) { }

  ngOnInit() {
    this.showLoginDialog();
  }

  showLoginDialog(){
    let dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if ( result ){
        var player = this.getPlayer();
        this.playerService.getGroups(player!.player_id.toString(),'0,1,2,3')
          .subscribe(resp => {
            var forward_url: string = this.localStorageService.get("forward_url");
            if (forward_url){
              this.localStorageService.set("forward_url", null);
              this.router.navigateByUrl(forward_url);
            }
            else{
              this.router.navigateByUrl("/dashboard");
            }
          });
      }
    });
  }

  getPlayer(): Player | null {
    return this.playerService.getLoggedInPlayerInfo();
  }
}
