<div style='width:100%;height:500px' fxLayout='column' fxLayoutAlign='center center'>
  <h1>OOPS!</h1>
  <div>
    <mat-icon color="warn">lock_person</mat-icon>
  </div>
  <h1>you're out of bounds bruh!</h1>
  <p style="width:400px">
    If you think you should have access to this, contact the administrator of this group.
  </p>

</div>