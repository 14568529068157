import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';
import { PlayerService } from 'src/app/services/player.service';
import { Group } from '../objects/group';
import { GroupService } from '../services/group.service';
import { SessionService } from '../services/session.service';
import { GroupSettings } from '../objects/group-settings';


@Injectable()
export class AuthorizedGuard implements CanActivate {

  constructor(
    private playerService : PlayerService,
    private sessionService: SessionService,
    private groupService: GroupService,
    private localStorage: LocalStorageService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    let player = this.playerService.getLoggedInPlayerInfo();
    if (player == null){
      return false;
    }
    let groups : Group[] = this.localStorage.get('groups-'+player.player_id.toString());
    let groupIds: number [] = groups.map( group => +group.group_id);
    let routeGroupId = +route.params['groupId'];
    let groupIndex = groupIds.findIndex(id => id == routeGroupId);
    this.groupService.getGroupSettings(routeGroupId)
      .subscribe( resp => {
        let groupSettings = GroupSettings.fromJson(JSON.parse(resp.d)[0]);;
        this.sessionService.setCurrentGroup(groupSettings);
      });



    if (player !== null && groupIndex > -1){
      
      return true;
    }
    console.log("Unauthorized!");
    this.router.navigateByUrl("/unauthorized");
    return false;
  }
}
