import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'wagl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.sessionService.removeCurrentGroup();
  }

  startNow(){
    
  }

}
