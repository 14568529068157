import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EventGames } from '../objects/event-games';
import { FoursomeGames } from '../objects/foursome-games';
import { ChicagoPoints } from '../objects/chicago-points';
import { eventRecurrence } from '../objects/event-recurrence';
import { GGKResponse } from 'src/app/objects/ggkResponse';
import { StablefordPoints } from '../objects/stableford-points';
import { Round } from '../objects/round';
import { Player } from '../objects/player';
import { MatchPlayMatchup } from '../objects/match-play-matchup';
import { RoundVerify } from '../objects/round-verify';
import { PricedItem } from '../objects/priced-item';
import { NewRoundRequest } from '../objects/new-round-request';
import { FlightInfo } from '../objects/flight-description';
import { GroupEvent } from '../objects/group-event';
import { PlayingFormatUnit } from '../objects/event_playing_format';
import { GroupEventGamePlayer } from '../objects/group_event_game_player';

const API_URL = environment.BASE_API_URL + 'GGKEventWebService.asmx/';
const GGK_API_KEY = environment.GGK_API_KEY;


@Injectable()
export class EventService {

  constructor(private http: HttpClient) {
  }

  post<T>(url: string, payload: any) {
    return this.http.post<T>(url, payload, { params: { 'api_key': GGK_API_KEY } })
  }

  getCalendarEvents(start_time: string, end_time: string, event_type: string, player_id: string): Observable<any> {

    let payload = { p_start: start_time, p_end: end_time, p_type: event_type, p_id: player_id };
    let url = API_URL + 'getCalendarEvents';

    return this.post<any>(url, payload);
  }

  getEventOnlyLiveResults(group_event_id: string, type: string) {
    let payload = { group_event_id: group_event_id, type: type, deep: false };
    let url = API_URL + 'getEventOnlyLiveResults';

    return this.post<any>(url, payload);

  }

  getLiveResults(_group_event_id: string, _type: string) {
    let payload = { group_event_id: _group_event_id, type: _type };
    let url = API_URL + 'getLiveResults';

    return this.post<any>(url, payload);
  }

  getEventVerifyRounds(group_event_id: string, event_scoring: string) {
    let payload = { group_event_id: group_event_id, event_scoring: event_scoring };
    let url = API_URL + 'getEventVerifyRounds';

    return this.post<any>(url, payload);
  }

  getEventInfo(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getGroupEventInfo';

    return this.post<any>(url, payload);
  }

  getEventBasics(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getEventBasics';

    return this.post<any>(url, payload);
  }

  getEventWinners(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getEventWinnersJSON';

    return this.post<any>(url, payload);
  }

  getSingleRoundWinners(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getSingleRoundWinners';

    return this.post<any>(url, payload);
  }


  getEventSkins(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getEventSkins';

    return this.post<any>(url, payload);
  }

  getEventExtraGamesDetails(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getExtraGamesHeaders';

    return this.post<any>(url, payload);
  }

  getPaceOfPlayReport(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getPaceOfPlayReport';

    return this.post<any>(url, payload);
  }

  getEventExtraGamesResults(group_event_id: string, game_id: string, flight: string) {
    let payload = {
      group_event_id: group_event_id,
      game_id: game_id,
      flight: flight
    };
    let url = API_URL + 'getExtraGamesResults';

    return this.post<any>(url, payload);
  }

  createEvent(_date: string, _time: string, _eventName: string, _group_id: number, _course_id: string, _tees_id: string, event_scoring: string, number_of_holes: string) {
    let payload = {
      dateText: _date, timeText: _time, eventName: _eventName,
      group_id: _group_id, player_count: '0', number_of_holes: number_of_holes,
      event_scoring: event_scoring, course_id: _course_id, tees_id: _tees_id
    };
    let url = API_URL + 'createEvent';

    return this.post<any>(url, payload);
  }

  createMultiRoundEvent(_date: string, _time: string, _eventName: string, _group_id: number, _is_ryder_cup: string) {
    let payload = {
      dateText: _date, timeText: _time, eventName: _eventName,
      group_id: _group_id, player_count: '0', number_of_holes: '18',
      event_scoring: '1', is_ryder_cup: _is_ryder_cup
    };
    let url = API_URL + 'createMultiRoundEvent';

    return this.post<any>(url, payload);
  }

  getProcessStatus(guid: string) {
    let payload = {
      session_id: guid
    }
    let url = API_URL + 'getProcessStatus';

    return this.post<any>(url, payload);
  }

  addEventToMultiRound(eventRound: NewRoundRequest) {
    let payload = {
      group_event_id: eventRound.group_event_id,
      dateText: eventRound.dateText,
      timeText: eventRound.timeText,
      eventName: eventRound.eventName,
      group_id: eventRound.group_id,
      player_count: eventRound.player_count,
      number_of_holes: eventRound.number_of_holes,
      event_scoring: eventRound.event_scoring,
      course_id: eventRound.course_id,
      tees_id: eventRound.tees_id,
      guid: eventRound.guid
    };
    let url = API_URL + 'addEventToMultiRound';

    return this.post<any>(url, payload);
  }

  //deprecated
  getRoundsFromMultiRounds(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getRoundsFromMultiRounds';

    return this.post<any>(url, payload);
  }

  waglGetEventRounds(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'waglGetEventRounds';

    return this.post<any>(url, payload);
  }


  getAvailableEventScoring(_group_id: number) {
    let payload = { group_id: _group_id };
    let url = API_URL + 'getAvailableEventScoring';

    return this.post<any>(url, payload);
  }

  getAvailablePointSystem(_group_id: number) {
    let payload = { group_id: _group_id };
    let url = API_URL + 'getAvailablePointSystem';

    return this.post<any>(url, payload);
  }

  getPayingSpots(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getPayingSpots';

    return this.post<any>(url, payload);
  }

  getEventGames(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getEventGames';

    return this.post<any>(url, payload);
  }

  getEventGame(game_id: number){
    let payload = { game_id: game_id };
    let url = API_URL + 'getEventGame';

    return this.post<any>(url, payload);
  }

  saveRegistrationDetails(group_event_id: string, registration_start: string, registration_end: string, hide_leaderboard: string){
    let payload = {
      group_event_id: group_event_id,
      registration_start: registration_start,
      registration_end: registration_end,
      hide_leaderboard: hide_leaderboard,
    }
    let url = API_URL + 'saveRegistrationDetails';

    return this.post<any>(url, payload);
  }

  setRyderCup(group_event_id: string, is_ryder_cup: string){
    let payload = {
      group_event_id: group_event_id,
      is_ryder_cup:is_ryder_cup
    }
    let url = API_URL + 'setRyderCup';

    return this.post<any>(url, payload);
  }

  saveEventBasics(group_event_id: string, dateText: string, timeText: string, eventName: string,
    foursome_count: string, point_system: string, is_private: string) {
    let payload = {
      group_event_id: group_event_id,
      dateText: dateText,
      timeText: timeText,
      eventName: eventName,
      foursome_count: foursome_count,
      point_system: point_system,
      is_private: is_private
    };
    let url = API_URL + 'saveEventBasics';

    return this.post<any>(url, payload);
  }

  saveEventGames(event_games: EventGames | null) {
    let url = API_URL + 'saveEventGames';

    return this.post<any>(url, event_games);
  }

  saveGroupEvent(event: GroupEvent){
    var dateTime = event.event_date.split(' ');
    let payload = {
      group_event_id: event.group_event_id,
      dateText: dateTime[0],
      timeText: dateTime[1],
      eventName: event.name,
      foursome_count: (+event.player_count/4).toString(),
      point_system: event.points_system,
      is_private: event.is_private,
      holes_to_play: event.nbr_of_holes,
      event_scoring: event.event_scoring,
      event_type: event.event_type
    };
    let url = API_URL + 'saveEventBasicsNoTees';
    return this.post<any>(url, payload);
  }

  updateRound(eventRound: NewRoundRequest) {
      let url = API_URL + 'updateRound';
      return this.post<any>(url, eventRound);
    }


  saveEventBasicsNoTees(group_event_id: string, dateText: string, timeText: string, eventName: string,
    foursome_count: string, point_system: string, is_private: string, holes_to_play: string, 
    event_scoring: string, event_type: string) {
    let payload = {
      group_event_id: group_event_id,
      dateText: dateText,
      timeText: timeText,
      eventName: eventName,
      foursome_count: foursome_count,
      point_system: point_system,
      is_private: is_private,
      holes_to_play: holes_to_play,
      event_scoring: event_scoring,
      event_type: event_type
    };
    let url = API_URL + 'saveEventBasicsNoTees';

    return this.post<any>(url, payload);
  }

  waglSaveEvent(groupEvent: GroupEvent){
    let payload = {
      groupEvent: groupEvent
    }
    let url = API_URL + 'waglSaveEvent';

    return this.post<any>(url, payload);
  }

  saveEventBasicsWithTees(group_event_id: string, dateText: string, timeText: string, eventName: string,
    foursome_count: string, point_system: string, is_private: string, holes_to_play: string, event_scoring: string, event_type: string, course_id: string, tees_id: string) {
    let payload = {
      group_event_id: group_event_id,
      dateText: dateText,
      timeText: timeText,
      eventName: eventName,
      foursome_count: foursome_count,
      point_system: point_system,
      is_private: is_private,
      holes_to_play: holes_to_play,
      event_scoring: event_scoring,
      event_type: event_type,
      course_id: course_id,
      tees_id: tees_id
    };
    let url = API_URL + 'saveEventBasicsWithTees';

    return this.post<any>(url, payload);
  }

  adminSaveRound(payload: Round) {
    let url = API_URL + 'adminSaveRound';

    return this.post<any>(url, payload);
  }

  deleteEvent(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'deleteEvent';

    return this.post<any>(url, payload);
  }

  getAdditionalScoringGames(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getAdditionalScoringGames';

    return this.post<any>(url, payload);
  }

  getPayFrontBackTotalWinners(group_event_id: number){
    let payload= { group_event_id: group_event_id, type: "1"};
    let url = API_URL + 'getPayFrontBackTotalWinners';

    return this.post<any>(url, payload);
  }

  getPayFrontBackTotal(group_event_id: number){
    let payload= { group_event_id: group_event_id };
    let url = API_URL + 'getPayFrontBackTotal';

    return this.post<any>(url, payload);
  }

  deletePayFrontBackTotal(group_event_id: number){
    let payload= { group_event_id: group_event_id };
    let url = API_URL + 'deletePayFrontBackTotal';

    return this.post<any>(url, payload);
  }


  saveUpdatePayFrontBackTotal(group_event_id : number, buyin : number, team_game : boolean, team_size: number, pay_count: number){
    let payload = {group_event_id : group_event_id, buyin : buyin, team_game : team_game, team_size: team_size, pay_count: pay_count}
    let url = API_URL + 'saveUpdatePayFrontBackTotal';

    return this.post<any>(url, payload);
  }


  addExtraGame(_group_event_id: string, _scoring_details_id: string) {
    let payload = { group_event_id: _group_event_id, scoring_details_id: _scoring_details_id };
    let url = API_URL + 'addExtraGame';

    return this.post<any>(url, payload);
  }

  deleteExtraGame(_group_event_id: string, _scoring_details_id: string) {
    let payload = { group_event_id: _group_event_id, scoring_details_id: _scoring_details_id };
    let url = API_URL + 'deleteExtraGame';

    return this.post<any>(url, payload);
  }

  getEventExtraGames(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getExtraGames';

    return this.post<any>(url, payload);
  }

  getEventFoursomeGames(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getEventFoursomeGames';

    return this.post<any>(url, payload);
  }

  saveEventFoursomeGames(eventFoursomeGames: FoursomeGames) {
    let url = API_URL + 'saveEventFoursomeGames';
    return this.post<any>(url, eventFoursomeGames);
  }

  getAvailableGroupPlayers(_group_id: number, _group_event_id: string) {
    let payload = { group_id: _group_id, group_event_id: _group_event_id };
    let url = API_URL + 'getAvailableGroupPlayersV2';

    return this.post<any>(url, payload);
  }


  getGroupEventPaymentStatus(_group_id: number, _group_event_id: string) {
    let payload = { group_id: _group_id, group_event_id: _group_event_id };
    let url = API_URL + 'getGroupEventPaymentStatus';

    return this.post<any>(url, payload);
  }

  getGroupMembershipDetails(membership_id: string) {
    let payload = { membership_id: membership_id };
    let url = API_URL + 'getGroupMembershipDetails';

    return this.post<any>(url, payload);
  }

  submitTournamentPayment(group_event_id: string, token: string, amount: number, note: string, player_id: string) {
    let payload = { group_event_id: group_event_id, token: token, amount: amount, note: note, player_id: player_id };
    let url = API_URL + 'submitTournamentPayment';

    return this.post<any>(url, payload);
  }

  getRegisteredPlayers(group_event_id: string, group_id: number){
    let payload = { group_event_id: group_event_id, group_id: group_id };
    let url = API_URL + 'getRegisteredPlayers';

    return this.post<any>(url, payload);

  }

  getPlayersAndFlight(group_event_id: number, event_playing_format_id : number, group_id: number){
    let payload = { 
      group_event_id: group_event_id, 
      event_playing_format_id: event_playing_format_id, 
      group_id: group_id };
    let url = API_URL + 'getPlayersAndFlight';

    return this.post<any>(url, payload);

  }

  getTeamsPlayers(group_event_id: number, event_playing_format_id : number, group_id: number){
    let payload = { 
      group_event_id: group_event_id, 
      event_playing_format_id: event_playing_format_id, 
      group_id: group_id };
    let url = API_URL + 'getTeamsPlayers';

    return this.post<any>(url, payload);
  }

  
  getGamePlayers(group_event_id: number, event_playing_format_id : number, group_id: number){
    let payload = { 
      group_event_id: group_event_id, 
      event_playing_format_id: event_playing_format_id, 
      group_id: group_id };
    let url = API_URL + 'getGamePlayers';

    return this.post<any>(url, payload);
  }


  getEventPlayers(_group_event_id: string) {
    let payload = { group_event_id: _group_event_id };
    let url = API_URL + 'getEventPlayers';

    return this.post<any>(url, payload);
  }

  getEventPlayersQuota(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getEventPlayersQuota';

    return this.post<any>(url, payload);
  }

  addPlayerToEvent(_group_event_id: string, _player_id: string, _force_add: boolean) {
    let payload = { group_event_id: _group_event_id, player_id: _player_id, forceAdd: _force_add };
    let url = API_URL + 'addPlayerToEvent';

    return this.post<any>(url, payload);
  }

  addPlayerToEventAsGuest(_group_event_id: string, _player_id: string) {
    let payload = { group_event_id: _group_event_id, player_id: _player_id };
    let url = API_URL + 'addPlayerToEventAsGuest';

    return this.post<any>(url, payload);
  }

  registerCTP(group_event_id: string, player_id: string) {
    let payload = { group_event_id: group_event_id, player_id: player_id };
    let url = API_URL + 'registerCTP';

    return this.post<any>(url, payload);
  }

  addPlayersToEvent(_group_event_id: string, _player_id: string) {
    let payload = { group_event_id: _group_event_id, playerIds: _player_id };
    let url = API_URL + 'addPlayersToEvent';

    return this.post<any>(url, payload);
  }

  addPlayerToFoursome(group_event_id: string, event_id: string, player_id: string, forceNewFoursome: boolean) {
    let payload = { group_event_id: group_event_id, event_id: event_id, player_id: player_id, forceNewFoursome: forceNewFoursome };
    let url = API_URL + 'addPlayerToFoursome';

    return this.post<any>(url, payload);
  }

  createFoursome(group_event_id: string, player_id: string) {
    let payload = { group_event_id: group_event_id, player_id: player_id };
    let url = API_URL + 'createFoursome';

    return this.post<any>(url, payload);
  }

  waglCreateFoursome(group_event_id: string) {
    let payload = { group_event_id: group_event_id};
    let url = API_URL + 'waglCreateFoursome';

    return this.post<any>(url, payload);
  }

  removeFoursome(event_id: string) {
    let payload = { event_id: event_id };
    let url = API_URL + 'removeFoursome';

    return this.post<any>(url, payload);
  }

  moveToNewFoursome(group_event_id: string, player_id: string) {
    let payload = { group_event_id: group_event_id, player_id: player_id };
    let url = API_URL + 'movePlayerToNewFoursome';

    return this.post<any>(url, payload);
  }

  addPlayersToFoursome(group_event_id: string, event_id: string, players: Player[]) {
    let payload = { group_event_id: group_event_id, event_id: event_id, players: players };
    let url = API_URL + 'addPlayersToFoursome';

    return this.post<any>(url, payload);
  }

  removePlayerFromEvent(_group_event_id: string, _player_id: string, _force_action: boolean) {
    let payload = { group_event_id: _group_event_id, player_id: _player_id, forceAction: _force_action };
    let url = API_URL + 'removePlayerFromEvent';

    return this.post<any>(url, payload);
  }

  removePlayersFromEvent(_group_event_id: string, _player_id: string) {
    let payload = { group_event_id: _group_event_id, playerIds: _player_id };
    let url = API_URL + 'removePlayersFromEvent';

    return this.post<any>(url, payload);
  }

  saveFlight(group_event_id: string, flight: string, roundArray: Round[]) {
    var player_ids = roundArray.map(x => x.player_id).join(',');
    let payload = { group_event_id: group_event_id, player_ids: player_ids, flight: flight };

    let url = API_URL + 'saveFlight';

    return this.post<any>(url, payload);
  }

  getFlightedData(group_event_id: string, group_id: number, force_reset: boolean, use_course_hcp: boolean, nbr_of_flights: number) {
    let payload = { group_event_id: group_event_id, group_id: group_id, force_reset: force_reset, use_course_hcp: use_course_hcp, nbr_of_flights: nbr_of_flights };

    let url = API_URL + 'getFlightedData';

    return this.post<any>(url, payload);
  }

  updateRoundAndGames(round_id: string, tees_id: string, index: string, off_low: string, in_prize_pool: string, net_skin: string, gross_skin: string) {
    let payload = {
      round_id: round_id, tees_id: tees_id, player_index: index, off_low: off_low,
      in_prize_pool: in_prize_pool, net_skin: net_skin, gross_skin: gross_skin
    };
    let url = API_URL + 'updateRoundAndGames';

    return this.post<any>(url, payload);
  }

  movePlayer(round_id: string, event_id: string, team_id: string) {
    let payload = { round_id: round_id, event_id: event_id, team_id: team_id };
    let url = API_URL + 'movePlayer';

    return this.post<any>(url, payload);
  }

  changeStartingHoleOrderAndTime(event_id: string, new_starting_hole: string, new_starting_order: string, new_start_time: string) {
    let payload = {
      event_id: event_id,
      new_starting_hole: new_starting_hole,
      new_starting_order: new_starting_order,
      new_start_time: new_start_time
    };
    let url = API_URL + 'changeStartingHoleOrderAndTime';

    return this.post<any>(url, payload);
  }

  changeStartingHoleAndOrder(event_id: string, new_starting_hole: string, new_starting_order: string) {
    let payload = {
      event_id: event_id,
      new_starting_hole: new_starting_hole,
      new_starting_order: new_starting_order
    };
    let url = API_URL + 'changeStartingHoleAndOrder';

    return this.post<any>(url, payload);
  }

  saveAndVerify(round_id: string, holes_score: string, holes_hcp: string, holes_par: string) {
    let payload = {
      round_id: round_id,
      holes_score: holes_score,
      holes_hcp: holes_hcp,
      holes_par: holes_par
    };
    let url = API_URL + 'saveAndVerify';

    return this.post<any>(url, payload);
  }

  saveStats(round_id: string, putts: string, putts_average: string, girs: string, girs_percent: string, fairways: string, fairway_percent: string) {
    let payload = {
      round_id: round_id,
      putts : putts,
      putts_average: putts_average,
      girs: girs,
      girs_percent: girs_percent,
      fairways: fairways,
      fairway_percent: fairway_percent
    };
    let url = API_URL + 'saveStats';

    return this.post<any>(url, payload);
  }

  performPostVerifyActions(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'performPostVerifyActions';

    return this.post<any>(url, payload);
  }

  getEventSponsors(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getEventSponsors';

    return this.post<any>(url, payload);
  }

  
  setEventStatus(group_event_id: string, status: string) {
    let payload = { group_event_id: group_event_id, status: status };
    let url = API_URL + 'setEventStatus';

    return this.post<any>(url, payload);
  }

  removeEventSponsor(id: string) {
    let payload = { id: id };
    let url = API_URL + 'removeEventSponsor';

    return this.post<any>(url, payload);
  }

  getAllEventsByGroupType(player_id: string, group_type: string, back_days: string, forward_days: string) {
    let payload = { player_id: player_id, group_type: group_type, back_days: back_days, forward_days: forward_days };
    let url = API_URL + 'getAllEventsByGroupType';

    return this.post<any>(url, payload);
  }

  getGroupEvents(player_id: string, group_id: number, month: string, year: string) {
    let payload = { player_id: player_id, group_id: group_id, month: month, year: year };
    let url = API_URL + 'getGroupEventsJson';

    return this.post<any>(url, payload);
  }

  getGroupEventsBySeasonId(player_id: string, group_id: number, season_id: string) {
    let payload = { player_id: player_id, group_id: group_id, season_id: season_id };
    let url = API_URL + 'getGroupEventsBySeasonIdJson';

    return this.post<any>(url, payload);
  }

  
  getGroupEventsBySeasonYear(player_id: string, group_id: number, year: string) {
    let payload = { player_id: player_id, group_id: group_id, year: year };
    let url = API_URL + 'getGroupEventsBySeasonYear';

    return this.post<any>(url, payload);
  }

  getStablefordPoints(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getStablefordPoints';

    return this.post<any>(url, payload);
  }

  setStablefordPoints(points: StablefordPoints) {
    let url = API_URL + 'setStablefordPoints';
    let payload = {
      group_event_id: points.group_event_id,
      albatros: points.albatros,
      eagle: points.eagle,
      birdey: points.birdey,
      par: points.par,
      bogey: points.bogey,
      doubleBogey: points.double,
      other: points.other
    }

    return this.post<any>(url, payload);
  }

  getChicagoPoints(group_event_id: string, event_scoring: string) {
    let payload = { group_event_id: group_event_id, event_scoring: event_scoring };
    let url = API_URL + 'getChicagoPoints';

    return this.post<any>(url, payload);
  }

  setChicagoPoints(points: ChicagoPoints) {
    let url = API_URL + 'setChicagoPoints';
    let payload = {
      group_event_id: points.group_event_id,
      start_score: points.start_score,
      albatros: points.albatros,
      eagle: points.eagle,
      birdey: points.birdey,
      par: points.par,
      bogey: points.bogey,
      doublebogey: points.doublebogey,
      other: points.other
    }

    return this.post<any>(url, payload);
  }

  copyEvent(group_event_id: string, date: string) {
    let payload = { group_event_id: group_event_id, date: date };
    let url = API_URL + 'copyEvent';

    return this.post<any>(url, payload);
  }

  getPricedItemTypes(group_id: number) {
    let payload = { group_id: group_id };
    let url = API_URL + 'getPricedItemTypes';

    return this.post<any>(url, payload);
  }

  waglGetPricedItemTypes(group_id: number) {
    let payload = { group_id: group_id };
    let url = API_URL + 'waglGetPricedItemTypes';

    return this.post<any>(url, payload);
  }

  

  getPricedList(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'waglGetPriceList';

    return this.post<any>(url, payload);
  }

  addPricedItem(pricedItem: PricedItem) {
    let payload = { group_event_id: pricedItem.group_event_id, item_type: pricedItem.item_type, item_price: pricedItem.item_price*100, optional: pricedItem.optional };
    let url = API_URL + 'addPricedItem';

    return this.post<any>(url, payload);
  }

  removePricedItem(item_id: string) {
    let payload = { item_id: item_id };
    let url = API_URL + 'removePricedItem';

    return this.post<any>(url, payload);
  }

  payEventEntryFee(token: string, amount: number, note: string, player_id: string, group_event_id: string) {
    let payload = {
      token: token,
      amount: amount,
      note: note,
      player_id: player_id,
      group_event_id: group_event_id,
    };
    let url = API_URL + 'payEventEntryFeeV2';

    return this.post<any>(url, payload);
  }

  registerAfterPayment(group_event_id: string, player_id: string,
    in_net_skins: string, in_gross_skins: string, in_prize_pool: string, ctp:string, 
    register_as_guest: boolean, credits_used: number, total_price: number) {
    let payload = {
      group_event_id: group_event_id,
      player_id: player_id,
      in_net_skins: in_net_skins,
      in_gross_skins: in_gross_skins,
      in_prize_pool: in_prize_pool,
      ctp: ctp,
      register_as_guest: register_as_guest,
      credits_used: credits_used,
      total_price: total_price
    };
    let url = API_URL + 'registerAfterPayment';

    return this.post<any>(url, payload);
  }

  getEventRegisteredPlayerCount(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getEventRegisteredPlayerCount';

    return this.post<any>(url, payload);
  }

  saveEventRecurrence(payload: eventRecurrence) {
    let url = API_URL + 'saveEventRecurrence';

    return this.post<any>(url, payload);
  }

  getEventRecurrence(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getEventRecurrence';

    return this.post<any>(url, payload);
  }

  isPlayerRegistered(group_event_id: string, player_id: string) {
    let payload = { group_event_id: group_event_id, player_id: player_id };
    let url = API_URL + 'isPlayerRegistered';

    return this.post<any>(url, payload);
  }

  sendEventInviteGuestEmail(group_event_id: string, sender_player_id: string, guest_email: string) {
    let payload = { group_event_id: group_event_id, sender_player_id: sender_player_id, guest_email: guest_email };
    let url = API_URL + 'sendEventInviteGuestEmail';

    return this.post<any>(url, payload);
  }

  loadRecordFromFile(group_id: number, group_event_id: string, first: string, last: string, email: string, start_hole: string, start_order: string, index: string, flight: string, asGuest: string) {
    let payload = {
      group_id: group_id,
      group_event_id: group_event_id,
      first: first,
      last: last,
      email: email,
      start_hole: start_hole,
      start_order: start_order,
      index: index,
      flight: flight,
      asGuest: asGuest
    };
    let url = API_URL + 'loadRecordFromFile';

    return this.post<any>(url, payload);
  }

  removeAllPlayersFromEvent(group_event_id: string) {
    let payload = { group_event_id: group_event_id }
    let url = API_URL + 'removeAllPlayersFromEvent';

    return this.post<any>(url, payload);
  }

  getMatchPlayMatchUps(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getMatchPlayMatchUps';

    return this.post<any>(url, payload);
  }

  saveMatchPlayMatch(payload: MatchPlayMatchup) {
    let url = API_URL + 'saveMatchPlayMatch';

    return this.post<any>(url, payload);
  }

  getMatchPlayTeamResult(group_event_id: string, type: string) {
    let payload = { group_event_id: group_event_id, type: type };
    let url = API_URL + 'getMatchPlayTeamResult';

    return this.post<any>(url, payload);
  }

  getMatchPlayCompletedTeamResult(group_event_id: string, type: string) {
    let payload = { group_event_id: group_event_id, type: type };
    let url = API_URL + 'getMatchPlayCompletedTeamResult';

    return this.post<any>(url, payload);
  }

  updateTeamHandicap(group_event_id: string, event_scoring: string) {
    let payload = { group_event_id: group_event_id, event_scoring: event_scoring };
    let url = API_URL + 'updateTeamHandicap';

    return this.post<any>(url, payload);
  }

  resetTeamHandicap(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'resetTeamHandicap';

    return this.post<any>(url, payload);
  }

  playOffLow(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'playOffLow';

    return this.post<any>(url, payload);
  }

  processPenalty(payload: RoundVerify) {
    let url = API_URL + 'processPenalty';

    return this.post<any>(url, payload);
  }

  createTwosomes(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'createTwosomes';

    return this.post<any>(url, payload);
  }

  postScore(player_id: string, date: string, course_id: string, tees_id: string, score: string, type: string, number_of_holes: string) {
    let payload = { player_id: player_id, date: date, course_id: course_id, tees_id: tees_id, score: score, type: type, nbr_of_holes: number_of_holes };
    let url = API_URL + 'postScore';

    return this.post<any>(url, payload);
  }

  postScoreManually(player_id: string, date: string, course_name: string, slope: string, rating: string, score: string, type: string, number_of_holes: string) {
    let payload = { player_id: player_id, date: date, course_name : course_name, slope: slope, rating: rating, score: score, type: type, nbr_of_holes: number_of_holes };
    let url = API_URL + 'postScoreManuallyApp';

    return this.post<any>(url, payload);
  }

  getPlayerRound(group_event_id: string, player_id: string){
    let payload = {group_event_id : group_event_id, player_id: player_id};
    let url = API_URL + "getPlayerRound";

    return this.post<any>(url, payload);
  }

  getProjectionSetup(group_event_id: string){
    let payload = {group_event_id : group_event_id };
    let url = API_URL + "getProjectionSetup";

    return this.post<any>(url, payload);
  }

  saveProjectionSetup(group_event_id: string, projectionSetupJson: string){
    let payload = {group_event_id : group_event_id, projectionSetupJson: projectionSetupJson };
    let url = API_URL + "saveProjectionSetup";

    return this.post<any>(url, payload);
  }

  getNetProjectedStandings(group_event_id: string){
    let payload = {group_event_id : group_event_id };
    let url = API_URL + "getNetProjectedStandings";

    return this.post<any>(url, payload);
  }

  getPairingRequests(group_event_id: string){
    let payload = {group_event_id : group_event_id };
    let url = API_URL + "getPairingRequests";

    return this.post<any>(url, payload);
  }

  loadEventPoints(group_id : string, group_event_id: string, add: boolean, fileData: string){
    let payload = {
      group_id: group_id,
      group_event_id : group_event_id,
      add_points: add,
      fileData: fileData
    };
    let url = API_URL + "loadEventPoints";
    
    return this.post<any>(url, payload);
  }

  getPayoutForEvent(group_event_id: string, number_of_spots: string, round_type: string){
    let payload = {
      group_event_id : group_event_id,
      number_of_spots: number_of_spots,
      round_type: round_type
    };
    let url = API_URL + "getPayoutsForAllFlights";
    
    return this.post<any>(url, payload);
  }

  resetPayoutForEvent(group_event_id: string, round_type: string){
    let payload = {
      group_event_id : group_event_id,
      round_type: round_type
    };
    let url = API_URL + "resetPayoutForEvent";
    
    return this.post<any>(url, payload);
  }

  savePayoutForFlight(group_event_id: string, number_of_spots: string, pay_type: string, round_type: string, pay_string: string, flight: string){
    let payload = {
      group_event_id : group_event_id,
      number_of_spots: number_of_spots,
      pay_type: pay_type,
      round_type: round_type,
      pay_string: pay_string,
      flight: flight
    };
    let url = API_URL + "savePayoutForFlight";
    
    return this.post<any>(url, payload);
  }

  addPlayerToCTP(group_event_id: string, player_id: string){
    let payload = {
      group_event_id : group_event_id,
      player_id: player_id
     };
    let url = API_URL + "addPlayerToCTP";

    return this.post<GGKResponse>(url, payload);
  }

  removePlayerFromCTP(group_event_id: string, player_id: string){
    let payload = {
      group_event_id : group_event_id,
      player_id: player_id
     };
    let url = API_URL + "removePlayerFromCTP";

    return this.post<any>(url, payload);
  }
  
  getRegistrationAudit(group_event_id: string) {
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getRegistrationAudit';

    return this.post<any>(url, payload);
  }  

  setContestExclusion(round_id: string, exclusion: string){
    let payload = { round_id: round_id, exclusion: exclusion };
    let url = API_URL + 'setContestExclusion';

    return this.post<any>(url, payload);
  }

  waglGetTeeSheet(group_event_id:string){
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'waglGetTeeSheet';

    return this.post<any>(url, payload);
  }

  getInvitedPlayers(group_event_id: number){
    let payload = { group_event_id: group_event_id };
    let url = API_URL + 'getInvitedPlayers';

    return this.post<any>(url, payload);
  }

  invitePlayerToEvent(group_event_id: string, player_id: string, invite: boolean){
    let payload = {
      group_event_id : group_event_id,
      player_id: player_id,
      invite: invite
     };
    let url = API_URL + "invitePlayerToEvent";

    return this.post<any>(url, payload);
  }

  clearEventInvitations(group_event_id: string){
    let payload = {
      group_event_id : group_event_id,
     };
    let url = API_URL + "clearEventInvitations";

    return this.post<GGKResponse>(url, payload);
  }

  saveFlightData(flightData: FlightInfo[], group_event_id: number, playingFormatids : string) : Observable<any>{
    let payload = {
      flights : flightData,
      group_event_id: group_event_id,
      playingFormatids: playingFormatids
     };
    let url = API_URL + "saveFlightData";

    return this.post<any>(url, payload);
    
  }

  calculateTeamIndex(playingFormatUnit: PlayingFormatUnit, group_event_id: number) : Observable<any>{
    let payload = {
      playingFormatUnit: playingFormatUnit,
      group_event_id: group_event_id
     };
    let url = API_URL + "calculateTeamIndex";

    return this.post<any>(url, payload);
  }

  
  saveGamePlayer(gamePlayer: GroupEventGamePlayer) : Observable<any>{
    let payload = {
      gamePlayer: gamePlayer
     };
    let url = API_URL + "saveGamePlayer";

    return this.post<any>(url, payload);
    
  }

  saveGamePlayers(gamePlayers: GroupEventGamePlayer[]) : Observable<any>{
    let payload = {
      gamePlayers: gamePlayers
     };
    let url = API_URL + "saveGamePlayers";

    return this.post<any>(url, payload);
    
  }

  saveScoringFormat(group_event_id: string, event_scoring: string) : Observable<any>{
    let payload = {
      group_event_id: group_event_id,
      event_scoring: event_scoring
     };
    let url = API_URL + "saveScoringFormat";

    return this.post<any>(url, payload);
  }

  // addEventAdditionalGame(group_event_id: string, game_id: string) : Observable<any>{
  //   let payload = {
  //     group_event_id: group_event_id,
  //     game_id: game_id
  //    };
  //   let url = API_URL + "addEventAdditionalGame";

  //   return this.post<any>(url, payload);
  // }
  
  // removeEventAdditionalGame(group_event_id: string, game_id: string) : Observable<any>{
  //   let payload = {
  //     group_event_id: group_event_id,
  //     game_id: game_id
  //    };
  //   let url = API_URL + "removeEventAdditionalGame";

  //   return this.post<any>(url, payload);
  // }

  // getEventAdditionalGame(group_event_id: string) : Observable<any>{
  //   let payload = {
  //     group_event_id: group_event_id
  //    };
  //   let url = API_URL + "getEventAdditionalGame";

  //   return this.post<any>(url, payload);
  // }

  waglAddGameFormat(playingFormat: PlayingFormatUnit): Observable<any>{
    let payload = {
      newGame: playingFormat
    }
    let url = API_URL + "waglAddGameFormat";
    return this.post<any>(url, payload);
  }

  waglDeleteGameFormat(game_id: number): Observable<any>{
    let payload =  {
      game_id: game_id
    }
    let url = API_URL + "waglDeleteGameFormat";
    return this.post<any>(url, payload);
  }

  waglGetAllGames(master_event_id: number): Observable<any>{
    let payload =  {
      master_event_id: master_event_id
    }
    let url = API_URL + "waglGetAllGames";
    return this.post<any>(url, payload);
  }

  waglGetAllEventGames(master_event_id: number) : Observable<any>{
      let payload =  {
        master_event_id: master_event_id
      }
      let url = API_URL + "waglGetAllEventGames";
      return this.post<any>(url, payload);
  }

  waglGetAllEventGamesForPlayingFormat(event_playing_format_id: number) : Observable<any>{
    let payload =  {
      event_playing_format_id: event_playing_format_id
    }
    let url = API_URL + "waglGetAllEventGamesForPlayingFormat";
    return this.post<any>(url, payload);
  }

  waglGetPlayingFormat(event_playing_format_id: number) : Observable<any>{
    let payload =  {
      event_playing_format_id: event_playing_format_id
    }
    let url = API_URL + "waglGetPlayingFormat";
    return this.post<any>(url, payload);
  }
  

  waglGetOneGame(master_event_id: number, event_playing_format_id: number): Observable<any>{
    let payload =  {
      master_event_id: master_event_id,
      event_playing_format_id: event_playing_format_id
    }
    let url = API_URL + "waglGetOneGame";
    return this.post<any>(url, payload);
  }

  waglSaveGame(eventGame: EventGames | null): Observable<any>{
    let payload =  {
      eventGame: eventGame
    }
    let url = API_URL + "waglSaveGame";
    return this.post<any>(url, payload);
  }

  removeAllRoundGames(game_id: string){
    let payload =  {
      game_id: game_id
    }
    let url = API_URL + "removeRoundGamesOfMultiRound";
    return this.post<any>(url, payload);
  }

  getWaglEventScoring(group_id: number) {
    let payload = { group_id: group_id };
    let url = API_URL + 'getWaglEventScoring';

    return this.post<any>(url, payload);
  }


  getAllRoundGames(game_id: string){
    let payload =  {
      game_id: game_id
    }
    let url = API_URL + "getAllRoundGamesOfMultiRound";
    return this.post<any>(url, payload);
  }

}
